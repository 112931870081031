<div style="color: #52525B;">
  {{guideDriverTitle}}
  <span nz-popover style="color: #1751b5; text-decoration: underline; cursor: pointer;"
    [nzPopoverTrigger]="'click'"
    [(nzPopoverVisible)]="isVisiable"
    [nzPopoverTitle]="tplTitle"
    [nzPopoverContent]="tplContent"
  >location instructions</span>

  <ng-template #tplTitle>
    <div class="flex flex-space-between" style="padding: 8px 0; align-items: center;">
      <div style="font-size: 16px; font-weight: 600;">Location Instructions</div>
      <div class="left10 center-children" ><i nz-icon nzType="close" nzTheme="outline" (click)="onClosePopover()" style="font-size: 18px; cursor: pointer;"></i></div>
    </div>
  </ng-template>

  <ng-template #tplContent>
    <div style="max-width: 400px;">{{instructions}}</div>
  </ng-template>
</div>