import { environment } from "@env/environment";
import { WebLibConfig } from "@wearewarp/ng-web";
import { Const } from "./Const";
import { AppConst } from "@app/const.generated";

const webLibConfig = new WebLibConfig({
  authServerUrl: environment.authUrl,
  globalEncryptKey: Const.ENCRYPT_KEY,
  globalEncryptIv: Const.ENCRYPT_IV,
  minutesLeftToRefreshToken: 5,       // Nếu 5 phút nữa hết hạn thì gia hạn luôn
  httpTimeout: 60000,
  clientApp: {
    id: Const.WORK_DISPATCHER_APP_ID,
    version: AppConst.Version,
    build: AppConst.BuildWhen,
    appHttpHeader: Const.APP_HTTP_HEADER,
  },
  isPublicUrl: (url: string) => {
    let _url = url.split('?')[0];
    return _url == `${environment.backendUrl}/v1/ss` || 
          _url.startsWith(`${environment.backendUrl}/v1/p/`) ||
          _url.startsWith(Const.API_SEARCH_US_CITIES);
  }
});

export default webLibConfig;