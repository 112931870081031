import { ApiService } from "@services/api.service";
import { BaseCompleteTaskService } from "./base.service";
import { TaskService } from "../task.service";
import { UIHelper } from "@services/UIHelper";
import { AnswersTaskOTPConfirmation } from "@wearewarp/types/data-model/types/work-queue";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { OTPConfirmationAnswerData } from "@app/interfaces/answer-data";


export class CompleteTaskOTPConfirmationService extends BaseCompleteTaskService {
  constructor(protected api: ApiService, private taskService: TaskService) {
    super(api);
  }

  public async completeTask(data) {
    let { jobId, answer } = data;
    if (! jobId || ! answer) {
      return UIHelper.showErr('[Complete service] Missing jobId or answer');
    }
    let type = <AnswersTaskOTPConfirmation> answer.type;
    switch (type) {
      case 'incorrectDriver':
        return this.markIncorrectDriver()
      case 'contactFailed':
        return this.markContactFailed(answer);
      default:
        return;
    }
  }

  private markIncorrectDriver() {
    const jobId = this.taskService?.getRouteEntity()?.getId();
    const driver = this.taskService?.getRouteEntity()?.getDriver();
    let reason = `Driver ${Utils.getFullName(driver)} said he’s not the driver for this load. Please take a look on this`;
    this.requestCarrierRecovery(jobId, reason);
  }

  private markContactFailed(answer: OTPConfirmationAnswerData) {
    const jobId = this.taskService?.getRouteEntity()?.getId();
    let needRecovery = answer?.data?.needRecovery;
    if (needRecovery) {
      const driver = this.taskService?.getRouteEntity()?.getDriver();
      let reason = `Driver ${Utils.getFullName(driver)} can’t be reached and didn't answer AFTER they confirmed booking`;
      return this.requestCarrierRecovery(jobId, reason);
    }
  }

  private async requestCarrierRecovery(jobId, reason) {
    let url = `${environment.backendUrl}/${Const.APIURI_WORK_QUEUE}/request-recovery`;
    this.api.POST(url, { jobId, reason }).subscribe({
      next: () => {
        Log.d('Request carrier recovery successfully');
      },
      error: (e) => { Log.e(e); }
    });
  }
}