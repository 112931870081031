import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ActionLogHistory, IByData, TimeWindow } from "@app/interfaces";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-util";
import { NzListModule} from "ng-zorro-antd/list";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { lastValueFrom } from "rxjs";

interface LogDetail {
  by: {
    name: string,
    when: string,
    longAgo: string   // bao lâu trước
  }
  desc?: string     // xử lý hết rồi chỉ dùng desc để hiển thị
}

interface LogDetailWithOrdinal extends LogDetail {
  ordinal: number,
  ordinalSuffix: string,
}

const CustomInputs = [
  NgIf, NgFor,
  NzListModule,
  NzToolTipModule,
  NzIconModule
]

@Component({
  selector: '[log-history]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class LogHistoryComponent {
  @Input() logs: ActionLogHistory[] = null;
  @Input() timezone: string = '';
  displayHistoryInfos: LogDetail[] = [];
  currentTime: string;    // timeiso utc

  showLogHistoryDialog: boolean = false;

  ngOnChanges() {
    this.currentTime = DateUtil.convertLocalTime(new Date(), DateUtil.getLocalTimeZone()).toISOString();
    this.buildDisplayInfos();
  }

  async buildDisplayInfos() {
    if (!this.logs) return;
    this.displayHistoryInfos = await Promise.all(this.logs.map(async (log,index): Promise<LogDetailWithOrdinal> => {
      // Dữ liệu cũ ko có action thì ko show log ra
      if (!log.actions) return;
      let updateBy = log?.update;
      let params: IByData = {
        collection: 'users',
        id: updateBy?.byId,
        warpId: updateBy?.by
      }
      let ordinal = this.logs.length - index;
      let ordinalSuffix = '';
      if (ordinal === 1) ordinalSuffix = 'st';
      if (ordinal === 2) ordinalSuffix = 'nd';
      if (ordinal === 3) ordinalSuffix = 'rd';
      if (ordinal > 3) ordinalSuffix = 'th';

      const res = await this.getWhenByData(params);
      return {
        ordinal: ordinal,
        ordinalSuffix: ordinalSuffix,
        by: {
          name: this.getFullName(res),
          when: updateBy?.when,
          longAgo: this.getTimeLongAgo(updateBy?.when)
        },
        desc: this.getDescFromLog(log)
      }
    }));
    this.displayHistoryInfos = this.displayHistoryInfos?.filter(it => it);
  }

  get isHasLogHistory() {
    return this.displayHistoryInfos && this.displayHistoryInfos.length > 0 ? true : false;
  }

  getLatestLog() {
    if (!this.displayHistoryInfos) return '';
    return this.displayHistoryInfos[0]?.desc || ''
  }

  onBtnCloseLogHistory() {
    this.showLogHistoryDialog = false;
  }

  async getWhenByData(params: IByData) {
    let url = `${Const.APIURI_USERS}/get-when-by-info`;
    const resp = await lastValueFrom(ApiService.instance.POST(url, params));
    return resp?.data;
  }

  private getFullName(user: {fullName?: string, firstName?: string, lastName?: string}) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    return [user.firstName || '', user.lastName || ''].join(' ').trim();
  }

  getTimeLongAgo(time: string) {
    if (!time) return '';
    // time và current time đều được convert về iso utc
    let window: TimeWindow = {
      from: time,
      to: this.currentTime
    }
    let duration = DateUtil.getTimeDurationFromWindow(window);
    duration = Math.round(duration / (1000 * 60));   // convert to mins
    return DateUtil.getParseDurationTimeAgo(duration);
  }

  getDescFromLog(log: ActionLogHistory): string {
    const type = log?.type;
    let data = log?.data;
    let actions = log?.actions || [];
    let desc = '';
    for (let action of actions) {
      switch (action) {
        case 'sendTrackingLink':
          desc = desc + 'Reminder sent to driver turn on live-tracking'; break;
        case 'markDriverRefusedLiveTracking':
          desc = desc + 'Driver refused to turn on live tracking'; break;
        case 'markContactDriverFailed':
          desc = desc + `Contact Driver Failed ${data?.note ? `"${data.note}"` : ''}`; break;
        case 'markContactCarrierFailed':
          desc = desc + `Contact Carrier Failed ${data?.note ? `"${data.note}"` : ''}`; break;
        case 'noteReason':
          if (type === 'driverNotFinishedLoading' || type === 'driverNotFinishedUnloading') {
            desc = desc + `Note delay reason "${data?.delayReason}"`; break;
          }
          if (type === 'driverLate') { desc = desc + `Note driver late reason "${data?.reason}"`; break; }
          if (type === 'driverNotPickup') { desc = desc + `Note driver can't pickup reason "${data?.reason}"`; break; }
        case 'updateETA':
          let eta = this.getDisplayTime(data?.eta);
          if (type === 'driverLate') desc = desc + `Marked "Driver might be late"\n`
          if (type === 'driverOnTime') desc = desc + `Marked "Driver will be on-time"\n`
          desc = desc + `Update ETA: ${eta}`; break;
        case 'updateATA':
          let ata = this.getDisplayTime(data?.ata);
          desc = desc + `Update ATA: ${ata}`; break;
        case 'updateDepartureTime':
          let departureTime = this.getDisplayTime(data?.departureTime);
          desc = desc + `Update Actual Departure Time: ${departureTime}`; break;
        case 'updateEstimateDepartureTime':
          let estimateDepartureTime = this.getDisplayTime(data?.estimateDepartureTime);
          desc = desc + `Update Estimate Departure Time: ${estimateDepartureTime}`; break
          
        // route confirmation actions
        case 'markCarrierKeepRoute':
          desc = desc + 'Marked carrier keep this route'; break;
        case 'markCarrierWaitingForConfirmation':
          desc = desc + `Marked carrier wants to keep this route but can't confirm via email during the call. Follow-up after ${data?.delayTime} mins`; break;
        case 'markCarrierDeclinedRoute':
          desc = desc + `Marked carrier declined this route "${data?.reason}"`; break;
        case 'markLoadTenderAccepted':
          desc = desc + 'Load Tender - Accepted'; break;
        case 'markEquipmentReady':
          desc = desc + 'Equipment - Confirmed ready'; break;
        case 'markDriverReady':
          desc = desc + `Driver - Assigned & Confirmed ready ${this.displayCurrentLocation(data?.driverLocation)} ${this.displayCurrentLocation(data?.secondaryDriverLocation, true)}`; break;
        case 'markConfirmBookingCompleted':
          desc = desc + 'Confirm Booking - Completed'; break;
        case 'sendConfirmBookingEmail':
          desc = desc + 'Sent Confirm Booking Email'; break;
        // otp confirmation actions
        case 'markConfirmedOTP':
          desc = desc + 'Marked OTP Confirmed'; break;
        case 'markIncorrectDriver':
          desc = desc + 'Marked Incorrect Driver'; break;
        case 'markContactedViaSms':
          desc = desc + 'Marked Contacted via Sms/Email'; break;
        case 'skipTask':
          desc = desc + 'Skip Task: ' + data?.note;
          break;
        default:
          break;
      }
      desc = desc + '\n';
    }
    return desc.trim();
  }

  displayCurrentLocation(currentLocation, isSecondary = false) {
    let currentLocationTxt = '- Driver location: ';
    if (isSecondary) currentLocationTxt = '- Secondary driver location: ';
    if (currentLocation?.city && currentLocation?.state) {
      return currentLocationTxt + `${currentLocation.city}, ${currentLocation.state}`;
    }
    if (currentLocation?.state) {
      return currentLocationTxt + `${currentLocation.state}`;
    }
    if (currentLocation?.city) {
      return currentLocationTxt + `${currentLocation.city}`;
    }
    return '';
  }

  getDisplayTime(time) {
    let timezone = this.timezone;
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime = DateUtil.displayLocalTime(time, timezone, 'MMM DD, YYYY h:mm A');
    return `${localTime} ${shortTz}`.trim();
  }

}
