export default class GPSFilter {
    private minAccuracy: number = 1;

    private speed: number;
    private timestamp: number; //miliseconds
    private lat: number;
    private lng: number;
    private variance: number;

    constructor() {
        this.variance = -1;
    }

    public setSpeed(speed: number) {
        this.speed = speed;
        return this;
    }

    public setState(lat: number, lng: number, accuracy: number, timestamp: number) {
        this.lat = lat;
        this.lng = lng;
        this.variance = accuracy * accuracy;
        this.timestamp = timestamp;
    }

    public process(newLat: number, newLng: number, accuracy: number, timestamp: number) {
        if (accuracy < this.minAccuracy) accuracy = this.minAccuracy;
        if (this.variance < 0) {
            this.timestamp = timestamp;
            this.lat = newLat;
            this.lng = newLng;
            this.variance = accuracy * accuracy;
        } else {
            let timeInc = timestamp - this.timestamp;
            if (timeInc > 100) {
                this.variance += timeInc * this.speed * this.speed / 1000;
                this.timestamp = timestamp;
            }
            else {
                // Log.d(`[GPSFilter] Skip location update.`);
                return {
                    latitude: this.lat,
                    longitude: this.lng,
                    accuracy: Math.sqrt(this.variance)
                }
            }

            const K = this.variance / (this.variance + accuracy * accuracy);
            this.lat += K * (newLat - this.lat);
            this.lng += K * (newLng - this.lng);
            this.variance = (1 - K) * this.variance;
        }
        return {
            latitude: this.lat,
            longitude: this.lng,
            accuracy: Math.sqrt(this.variance)
        }
    }
}