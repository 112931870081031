import { Component, Input } from "@angular/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPopoverModule } from "ng-zorro-antd/popover";

const CustomInputs = [
  NzPopoverModule,
  NzIconModule
]

@Component({
  selector: '[location-instruction]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: [],
})
export class LocationInstructionComponent {
  private _instructions;
  @Input() set instructions(value) {
    this._instructions = value;
  }
  get instructions() { return this._instructions }

  get guideDriverTitle() {
    return `If Driver don't know how to access the stop, please guide them using`;
  }

  public isVisiable: boolean = false;
  onClosePopover() {
    this.isVisiable = false;
  }
}