

<ng-container *ngIf="firstLoading == true">
  <div class="container loading-container nodata">
    <i nz-icon nzType="loading" nzTheme="outline"></i>
  </div>
</ng-container>
<ng-container *ngIf="firstLoading == 'NG'">
  <div class="container loading-container" style="flex-direction: column;">
    <span style="font-size: 16px; color: #969696;">{{txtAppInitError}}</span>
    <a [href]="currentUrl" style="margin-top: 20px; font-weight: bold; font-size: 16px;">
      <i nz-icon nzType="sync" nzTheme="outline" style="margin-right: 10px;"></i>Refresh
    </a>
  </div>
</ng-container>
<ng-container *ngIf="firstLoading == 'OK'">
  <div style="height: 100%;">
    <div style="width: 100%; padding: 0; overflow: hidden;">
      <router-outlet (activate)="onRouterActivate($event)" (deactivate)="onRouterDeactivate($event)"></router-outlet>
    </div>
  </div>
</ng-container>