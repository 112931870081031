<div *ngIf="isHasLogHistory">
  <!-- {{ getLatestLog() }} -->
  <!-- <span class="btn-log-history"
    nz-tooltip 
    [nzTooltipTrigger]="'click'"
    [nzTooltipTitle]="tplLogHistory"
    [nzTooltipPlacement]="'leftTop'"
    [nzTooltipColor]="'white'"
    [(nzTooltipVisible)]="showLogHistoryDialog"
    nzTooltipOverlayClassName="tooltip-log-history"
  >Log History
</span> -->
  
  <div class="history-container">
    <div class="flex flex-space-between">
      <div><span style="font-weight: 600">Log History</span></div>
    </div>
    <div class="separator h top10 bottom10"></div>
    <nz-list class="content-box" [nzDataSource]="displayHistoryInfos" [nzRenderItem]="logItem">
      <ng-template #logItem let-item>
        <nz-list-item>
          <div class="log-item" style="width: 100%">
            <div class="flex flex-space-between">
                <div class="flex flex-direction-column" style="justify-content:center; align-items: center;">
                  <div style="
                  display: flex; justify-content: center; align-items: center;
                  margin-right: 10px;
                  width:28px; height: 16px; 
                  border-radius: 9px; 
                  background-color: #f2f2f3;">
                    <span style="font-weight: 500; color: #101014; font-size: 12px; line-height: 14px;
                    ">
                    {{item.ordinal}}{{item.ordinalSuffix}}
                    </span>
                  </div>
                  <div>
                    <span style="color:#a1a1aa">  by {{ item.by?.name || 'N/A' }}</span>
                  </div>
              </div>
              <div style="color:#a1a1aa">{{ item.by?.longAgo || 'N/A' }}</div>  
            </div>
            <div class="top10">
              <span style="white-space: pre-line; font-weight: 400;">{{item.desc || 'N/A'}} </span>
            </div>

          </div>
        </nz-list-item>
      </ng-template>
    </nz-list>
  </div>
<!-- 
  <ng-template #tplLogHistory>
    <div>
      <div class="flex flex-space-between">
        <div><span style="font-weight: 600;">Log History</span></div>
        <div><i nz-icon nzType="close" nzTheme="outline" (click)="onBtnCloseLogHistory()" style="cursor: pointer;"></i></div>
      </div>
      <div class="separator h top10 bottom10"></div>

      <div class="content-box" style="max-height: 300px; overflow-y: auto;">
        <div *ngFor="let item of displayHistoryInfos; let i = index">
          <div *ngIf="i > 0" class="separator h top10 bottom10"></div>
          <div class="log-item">
            <div class="flex flex-space-between">
              <div style="font-weight: 500;">{{ item.by?.name || 'N/A' }}</div>
              <div style="color:#d4d4d4">{{ item.by?.longAgo || 'N/A' }}</div>  
            </div>
            <div class="top10"><span style="white-space: pre-line;">{{item.desc || 'N/A'}}</span></div>
          </div>
        </div>  
      </div>
    </div>
  </ng-template> -->
</div>