import { ModalOptions, NzModalService, OnClickCallback } from "ng-zorro-antd/modal";
import { getInjector } from ".";
import { Utils } from "./utils";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TemplateRef, Type } from "@angular/core";
import { ConfirmDeleteOptions } from "@app/interfaces";

export interface ConfirmModalOptions {
  title?: string;
  okText?: string;
  cancelText?: string;
  className?: string;
  cancelDisabled?: boolean;
}

export class UIHelper {
  private static _notification: NzNotificationService;

  static get notification(): NzNotificationService {
    if (!this._notification) {
      let injector = getInjector();
      this._notification = injector.get(NzNotificationService);
    }
    return this._notification;
  }

  static showErr(err): void {
    let msg = Utils.getErrorString(err);
    this.notification.create('error', 'Error', msg);
  }

  static showSuccess(resp, title = 'Success'): void {
    let msg = Utils.getSuccessString(resp);
    this.notification.create('success', title, msg);
  }

  static showInfo(message: any): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('info', 'Notification', msg);
  }

  static showWarning(title: any = null, message: any): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('warning', title ?? 'Notification', msg);
  }

  static showDialog(message: string|TemplateRef<any>, onOK = () => {}) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: onOK,
      nzCancelText: null
    });
  }

  static confirm(message: string, onOk: OnClickCallback<void>, ops: ConfirmModalOptions = {}) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzTitle: ops.title,
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: ops.okText || 'OK',
      nzOnOk: onOk,
      nzCancelText: ops.cancelText || 'Cancel',
      nzCancelDisabled: ops.cancelDisabled || false,
      nzClassName: ops.className
    });
  }

  static confirmYesNo(message: string, onOk: OnClickCallback<void>) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.confirm({
      nzTitle: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: onOk,
      nzCancelText: 'No',
    });
  }


  static confirmDeletion(ops: ConfirmDeleteOptions) {
    let fnCancel = ops.fnCancel;
    if (!Utils.isFunction(fnCancel)) {
      fnCancel = () => {
      }
    }
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.confirm({
      nzTitle: ops.message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: ops.txtBtnOk || 'Delete',
      nzOnOk: ops.fnOk,
      nzOkDanger: true,
      nzCancelText: ops.txtBtnCancel || 'Cancel',
      nzOnCancel: fnCancel
    });
  }

  static open<T>(content: string | TemplateRef<T> | Type<T>, options: ModalOptions<T>) {
    const injector = getInjector();
    const modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: content,
      ...options
    });
  }
}
