<div>
  <div *ngIf="!isRefusedLiveTracking">
    <button *ngIf="isSent" nz-button nzType="text" nzTheme="outline" [disabled]="true">Reminder sent</button>
    <button *ngIf="!isSent" nz-button nzType="default" nzTheme="outline"
      nz-tooltip
      [nzTooltipTrigger]="'click'"
      [nzTooltipTitle]="tplSendTrackingLink"
      [nzTooltipPlacement]="'top'"
      [nzTooltipColor]="'white'"
      nzTooltipOverlayClassName="send-tracking-tooltip-info"
    >Send Tracking Link</button>

    <button nz-button nzType="default" nzTheme="outline" (click)="onDriverRefuse()" class="left10">Driver Refuse</button>
  </div>
  <div *ngIf="isRefusedLiveTracking">
    <div class="driver-refused">Driver Refused</div>
  </div>

  <ng-template #tplSendTrackingLink>
    <div class="tpl-send-tracking-link" style="width: 350px;">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canSend" (click)="onBtnSendTrackingLink()">Send</button>
      </div>
      <div class="top15">
        <div>Tracking link<span class="label-mark-required"></span></div>
        <textarea nz-input 
          [placeholder]="trackingLinkExample" 
          [(ngModel)]="trackingLink"
          style="width: 100%; margin-top: 10px;"
          [nzAutosize]="{ minRows: 2, maxRows: 3}"
        ></textarea>
      </div>
      <div class="top15">
        <div>Predefined message</div>
        <textarea nz-input 
          [placeholder]="'Enter message'" 
          [(ngModel)]="message"
          style="width: 100%; margin-top: 10px;"
          [nzAutosize]="{ minRows: 5, maxRows: 5}"
        ></textarea>
      </div>
    </div>
  </ng-template>
</div>