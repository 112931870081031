import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@app/dialogs/dialog.service";
import RouteEntity from "@app/work-dispatcher/task-queue/entity/RouteEntity";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { AssignDriverDialog } from "../assign-driver";
import { RouteService } from "@app/work-dispatcher/task-queue/services/route.service";
import { InputHelper } from "@services/input-helper";
import { IByData } from "@app/interfaces";
import { GetWhenByService } from "@app/work-dispatcher/task-queue/services/getWhenBy.service";

const CustomInputs = [
  NgIf, NgFor,
  NzGridModule,
  NzButtonModule,
  NzStepsModule,
]

@Component({
  selector: '[route-check-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class RouteCheckListComponent {
  @Output() onAllItemsConfirmed: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCompletedCheckListItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected api: ApiService, private routeService: RouteService, private getWhenByService: GetWhenByService) {
  }

  routeEntity: RouteEntity;
  isLoading: boolean = false;
  itemArr = ['pickupServices', 'dropoffServices', 'pickupInstructions', 'dropoffInstructions'];
  isConfirmedLoadtender: boolean = false;
  isConfirmedReady: boolean = false;
  isConfirmedDriverReady: boolean = false;
  isAssignedDriver: boolean = false;
  confirmedDriverBy;
  confirmedLTBy;
  confirmedEquipmentBy;
  displayInfo: any = {};

  ngOnInit() {
    this.routeEntity = this.routeService.getRoute();
    this.buildDisplayInfo();
  }

  get showLoadTenderStep() {
    return this.routeEntity?.getData()?.settings?.isRequireCarrierAcceptLoadTender;
  }

  get confirmLTStepTitle() {
    if (this.isConfirmedLoadtender && this.confirmedLTBy) {
      return this.displayInfo?.confirmedLoadtenderText;
    }
    return '';
  }

  get confirmReadyStepTitle() {
    if (this.isConfirmedReady && this.confirmedEquipmentBy) {
      return this.displayInfo?.confirmedReadyText;
    }
    return `Ask "Is your equipment (${this.getDisplayEquipment()}) available for the pickup?"`;
  }

  get assignDriverStepTitle() {
    if (this.isConfirmedDriverReady && this.confirmedDriverBy) {
      return this.displayInfo?.confirmedDriverText;
    }
    return `Ask "Who is your driver?"`;
  }

  get remindTitle() {
    return `Remind "Thank you for your confirmation, next time please confirm via Booking confirmation email"`;
  }

  get confirmDriverTitle() {
    return this.isAssignedDriver ? 'Driver' : 'Assign Driver';
  }

  get assignDriverTeamRequired() {
    return this.routeEntity.getRequiredVehicle()?.options?.includes('TEAM') || false;
  }

  get driverNameAndPhone() {
    const driver = this.routeEntity.getDriver();
    const phone = InputHelper.formatPhone(driver.phone);
    if(phone) {
      return `${driver?.firstName} ${driver?.lastName}, ${phone}`;
    }
    return `${driver?.firstName} ${driver?.lastName}` || 'N/A';
  }

  get driverLocation() {
    const currentLocation = this.routeEntity.getAssignedDriver()?.currentLocation || {};
    return (currentLocation?.city || currentLocation?.state) ? `${currentLocation?.city ? currentLocation.city + ', ' : ''}${currentLocation?.state}` : 'N/A';
  }

  get secondaryDriverNameAndPhone() {
    const secondaryDriver = this.routeEntity.getAssignedSecondaryDrivers()?.[0] || null;
    if(!secondaryDriver) return 'N/A';
    const phone = InputHelper.formatPhone(secondaryDriver.phone);
    if(phone) {
      return `${secondaryDriver?.firstName} ${secondaryDriver?.lastName}, ${phone}`;
    }
    return `${secondaryDriver?.firstName} ${secondaryDriver?.lastName}`;
  }

  get secondaryDriverLocation() {
    const secondaryDriver = this.routeEntity.getAssignedSecondaryDrivers()?.[0] || null;
    if(!secondaryDriver) return 'N/A';
    const currentLocation = secondaryDriver?.currentLocation || {};
    return (currentLocation?.city || currentLocation?.state) ? `${currentLocation?.city ? currentLocation.city + ', ' : ''}${currentLocation?.state}` : 'N/A';
  }

  get assignDriverTitle() {
    return this.isAssignedDriver ? 'Change' : 'Assign & Confirm';
  }

  get shouldShowConfirmDriverBtn() {
    return this.isAssignedDriver && !this.isConfirmedDriverReady;
  }

  async buildDisplayInfo() {
    let stops = this.routeEntity.getStops();
    let pickupStop = stops.find(stop => stop.getType() === Const.RouteTaskType.PICKUP);
    let dropoffStop = stops.find(stop => stop.getType() === Const.RouteTaskType.DROPOFF);
    let puServiceOptions = pickupStop?.getServiceOptions() || [];
    let doServiceOptions = dropoffStop?.getServiceOptions() || [];

    this.displayInfo = {
      equipment: this.getDisplayEquipment(),
      pickupServices: puServiceOptions.join(', '),
      dropoffServices: doServiceOptions.join(', '),
      pickupInstructions: pickupStop?.getDeliveryInfo()?.instructions,
      dropoffInstructions: dropoffStop?.getDeliveryInfo()?.instructions,
    }
    this.isConfirmedLoadtender = this.routeEntity?.getConfirmLoadtenderStatus() === 'accepted' ? true : false;
    this.isConfirmedReady = this.routeEntity?.getConfirmReadyStatus() || false;
    this.isConfirmedDriverReady = this.routeEntity?.getConfirmDriverStatus() || false;
    this.isAssignedDriver = this.routeEntity?.getDriverId() ? true : false;
    if (this.allItemsConfirmed) {
      this.onAllItemsConfirmed.emit(true);
    }
    let timezone = pickupStop?.getTimeZoneStandard();
    let collection;
    if (this.isConfirmedLoadtender) {
      let confirmedLTInfo = this.routeEntity?.getAcceptedLoadtenderBy();
      this.confirmedLTBy = await this.getWhenByData(confirmedLTInfo);
      collection = confirmedLTInfo?.collection;
      this.displayInfo['confirmedLoadtenderText'] = `Confirmed by ${this.getWhenByService.getFullName(this.confirmedLTBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedLTInfo?.when, timezone)}`;
    }
    if (this.isConfirmedReady) {
      let confirmedReadyInfo = this.routeEntity?.getConfirmedReadyBy();
      this.confirmedEquipmentBy = await this.getWhenByData(confirmedReadyInfo);
      collection = confirmedReadyInfo?.collection;
      this.displayInfo['confirmedReadyText'] = `Confirmed by ${this.getWhenByService.getFullName(this.confirmedEquipmentBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedReadyInfo?.when, timezone)}`;
    }
    if (this.isConfirmedDriverReady) {
      let confirmedReadyInfo = this.routeEntity?.getConfirmedDriverReadyBy();
      this.confirmedDriverBy = await this.getWhenByData(confirmedReadyInfo);
      collection = confirmedReadyInfo?.collection;
      this.displayInfo['confirmedDriverText'] = `Confirmed by ${this.getWhenByService.getFullName(this.confirmedDriverBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedReadyInfo?.when, timezone)}`;
    }
  }

  get allItemsConfirmed() {
    if (!this.showLoadTenderStep) {
      return (this.isConfirmedReady && this.isConfirmedDriverReady)
    }
    return this.isConfirmedLoadtender && this.isConfirmedReady && this.isConfirmedDriverReady;
  }

  getDisplayEquipment() {
    let vehicle = this.routeEntity.getRequiredVehicle();
    if (!vehicle) return 'N/A';
    if (vehicle.options?.length) {
      return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`;
    }
    return vehicle.name;
  }

  getItemLabel(key) {
    switch(key) {
      case 'pickupServices': return 'PU Service';
      case 'dropoffServices': return 'DO Service';
      case 'pickupInstructions': return 'PU Instructions';
      case 'dropoffInstructions': return 'DO Instructions';
    }
  }

  getItemValue(key: string) {
    return this.displayInfo[key] || 'N/A';
  }

  public confirmReadyLoading: boolean = false;
  onBtnConfirmReady() {
    let jobId = this.routeEntity?.getId() || '';
    let carrierId = this.routeEntity?.getCarrierId() || '';
    if (!jobId) return UIHelper.showErr('Load id is required!');
    if (!carrierId) return UIHelper.showErr('Carrier id is required!');
    this.confirmReadyLoading = true;
    const params = { jobId, carrierId };
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/confirm_ready`);
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        this.confirmReadyLoading = false;
        this.isConfirmedReady = true;
        UIHelper.showSuccess('Confirm ready successfully');
        this.refreshRouteData();
        this.updateCheckList();
        this.onCompletedCheckListItem.emit('confirmReady');
      },
      error: (e) => {
        this.confirmReadyLoading = false;
        UIHelper.showErr(e);
      },
    })
  }

  public confirmDriverReadyLoading: boolean = false;
  async onConfirmDriverReady(isShowMessage: boolean = true) {
    this.confirmDriverReadyLoading = true;
    let jobId = this.routeEntity?.getId() || '';
    if (!jobId) return UIHelper.showErr('Load id is required!');
    let driverId = this.routeEntity?.getDriverId() || '';
    const params = { jobId, driverId };
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/confirm_driver_ready`);
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        if(isShowMessage) UIHelper.showSuccess('Confirm driver ready has sent successfully!');
        this.confirmDriverReadyLoading = false;
        this.refreshRouteData();
        this.updateCheckList();
        this.onCompletedCheckListItem.emit('confirmDriverReady');
      }, 
      error: (e) => {
        UIHelper.showErr(e);
        this.confirmDriverReadyLoading = false;
      }
    });
  }

  async onBtnAssignDriver() {
    DialogService.openFormDialog(AssignDriverDialog, {
      nzData: {
        updateSuccess: async (resp) => { 
          await this.refreshRouteData();
          if (this.routeEntity?.getDriverId()) {
            await this.onConfirmDriverReady(false);
          }
          this.updateCheckList();
          this.onCompletedCheckListItem.emit('assignDriver');
        },
        routeEntity: this.routeEntity,
        closeOnSuccess: true,
      },
      nzMaskClosable: false,      
    })
  }

  async refreshRouteData() {
    this.isLoading = true;
    await this.routeService.refresh();
    this.routeEntity = this.routeService.getRoute();
    this.buildDisplayInfo();
    this.isLoading = false;
  }

  updateCheckList() {
    if (this.allItemsConfirmed) {
      this.onAllItemsConfirmed.emit(true);
    }
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }
}