import { Component, EventEmitter, Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { App, setApp, setInjector } from './services';
import { NavigationEndData, NavigationUrlData, WorkOperatorAuthUser } from './interfaces';
import { Subscription, filter, lastValueFrom } from 'rxjs';
import { ApiService } from '@services/api.service';
import { AuthService, EventAuthUserChange } from '@wearewarp/ng-web';
import { Utils } from '@services/utils';
import { UIHelper } from '@services/UIHelper';
import { Const } from '@const/Const';
import { requirePermissions, verifyPasswordExpired } from '@services/auth.check-role';
import { BaseComponent } from '@abstract/BaseComponent';
import { Title } from '@angular/platform-browser';
import { SocketService } from '@services/socket.service';
import to from 'await-to-js';
import { environment } from '@env/environment';
import { CommonModule } from '@angular/common';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { enUS } from 'date-fns/locale';

const AppCustomInputs = [
  CommonModule,
  RouterModule,
  FormsModule,
  NzNotificationModule,
  NzModalModule,
  NzIconModule
]
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [AppCustomInputs],
  providers: [
    ApiService,
    SocketService,
  ],
  templateUrl: './app.html'
})
@Injectable()
export class AppComponent implements App, OnDestroy  {
  private authUser: WorkOperatorAuthUser | undefined;
  public firstLoading: boolean|'OK'|'NG';
  private activatedComponent;
  public windowResizeListener: EventEmitter<any> = new EventEmitter<any>();

  private pendingNavigationEndUrl = null;
  private _currentNavigationUrlData: NavigationUrlData;
  get currentNavigationUrlData(): NavigationUrlData { return this._currentNavigationUrlData }
  public navigationEnd = new EventEmitter<NavigationEndData>();

  public txtAppInitError = 'Cannot connect to the server, please try again later';
  public get currentUrl(): string { return this.router.url }

  private subscription: Subscription = new Subscription();

  constructor(
    injector: Injector, 
    private i18n: NzI18nService,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    setInjector(injector);
    this.i18n.setDateLocale(enUS); 
    setApp(this);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
      }
    });
    if (this.auth.alreadyLoggedIn()) {
      this.didLogin();
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      var rt = this.getChild(this.activatedRoute)
      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title ?? 'WARP Work')
      })
    });
    this.subscription.add(this.auth.authChanged.subscribe(ev => {
      switch (ev.code) {
        case EventAuthUserChange.login:
          return this.didLogin();
        case EventAuthUserChange.logout:
          return this.didLogout();
        default: return;
      }
    }));
    this.firstInit();
  }

  getCurrentNavigationUrlData() {
    return this.currentNavigationUrlData;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private handleNavigationEnd(event: NavigationEnd) {
    let data = this.createNavigationEndData();
    this._currentNavigationUrlData = Utils.cloneObject(data.current);
    this.navigationEnd.emit(data);
    if (!this.activatedComponent) {
      // App initialization has not completed yet so component has not been activated
      if (event.url.length > '/'.length) {
        this.pendingNavigationEndUrl = event.url;
      }
    } else if (this.activatedComponent instanceof BaseComponent) {
      // Only activatedComponent is allow to handle
      (<BaseComponent>this.activatedComponent).onNavigationEnd(event.url);
    }
  }

  createNavigationEndData(): NavigationEndData {
    let urlData: NavigationUrlData = {
      url: location.pathname + location.search,
      urlParams: this.activatedRoute.snapshot.params,
      queryParams: this.activatedRoute.snapshot.queryParams
    };
    return {previous: this._currentNavigationUrlData, current: urlData};
  }

  subscribeWindowResize(next) {
    return this.windowResizeListener.subscribe(next)
  }

  subscribeNavigationEnd(next) {
    return this.navigationEnd.subscribe(next);
  }

  getAuthUser() {
    return this.authUser;
  }

  public isFirstLoadingOK(): boolean {
    return this.firstLoading == 'OK';
  }
  
  protected firstInit() {
    this.firstLoading = true;
    this.initSession();
  }

  protected async firstInitDone(resp, err) {
    if (err) {
      this.firstLoading = 'NG';
      return UIHelper.showErr(err);
    }
    await this.getMyProfile();
    this.firstLoading = 'OK';
  }

  private initSession() {
    this.api.POST(Const.API_SESSION()).subscribe({
      next: (resp) => {
        this.firstInitDone(resp, null);
      },
      error: (err) => {
        this.firstInitDone(null, err);
      }
    })
  }

  private async getMyProfile() {
    if (!this.auth.alreadyLoggedIn()) {
      return;
    }
    let [err, resp] = await to(lastValueFrom(this.api.GET(Const.APIURI_MY_PROFILE)));
    if (err) {
      return UIHelper.showErr(err);
    }
    this.authUser = resp.data;
    this.checkPasswordExpired(resp.data);
    this.setUserIdForMsClarity(this.authUser);
  }

  public checkPasswordExpired(data: any) {
    if(!data) return;
    return verifyPasswordExpired(data, this.router);
  }

  public async loginSucceeded() {
    await this.getMyProfile();
  }

  public async myProfileChanged() {
    await this.getMyProfile();
  }
  
  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  onRouterActivate(compRef) {
    if(!this.hasPermissions()){
      this.router.navigate(['/']);
      return;
    }
    this.activatedComponent = compRef;
    if (compRef instanceof BaseComponent) {
      let comp: BaseComponent = compRef;
      if (this.pendingNavigationEndUrl) {
        comp.onNavigationEnd(this.pendingNavigationEndUrl);
        this.pendingNavigationEndUrl = null;
      }
    }
  }

  hasPermissions() {
    const rt = this.getChild(this.activatedRoute)
    const permissions = rt?.snapshot?.data?.permissions;
    if(!permissions) return true;
    return requirePermissions(this.authUser, permissions);
  }

  onRouterDeactivate(compRef) {
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private setUserIdForMsClarity(authUser) {
    const userId = `[${environment.type}] ${authUser.warpId}-${authUser.email}`;
    (<any>window)?.clarity("set", "userId", userId);
  }

  protected didLogin() {
    this.socketService.connect();
    this.checkPasswordExpired(this.authUser);
  }

  protected didLogout() {
    this.socketService.disconnect();
  }

}


