import { Injectable, Type } from "@angular/core";
import { getInjector } from "@services/index";
import { ModalOptions, NzModalService } from "ng-zorro-antd/modal";
import { DlgPreviewImgComponent } from "./dlg-preview-img";
import { BaseFormDialog } from "./base-form-dialog";

export interface ModalDataImg {
  imgUrl: string;
}

@Injectable()
export class DialogService {
  public static previewImg(url: string) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create<DlgPreviewImgComponent, ModalDataImg>({
      nzContent: DlgPreviewImgComponent,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzClassName: 'modal-preview-img modal-fullscreen',
      nzData: {
        imgUrl: url
      }
    });
  }

  public static openFormDialog<T extends BaseFormDialog>(component: Type<T>, options?: ModalOptions<T>) {
    let modalRef;
    let defaultOptions: ModalOptions<T> = {nzClosable: false, nzMaskClosable: false, nzKeyboard: false};
    let ops: ModalOptions<T> = Object.assign(defaultOptions, options || {});
    let className = ops.nzClassName || '';
    if (className.length > 0) {
      className += ' ';
    }
    className += 'modal-work-queue';
    ops.nzClassName = className;
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    wrapClassName += 'vertical-center-modal';
    ops.nzWrapClassName = wrapClassName;
    ops.nzContent = component;
    if (ops.nzFooter === undefined) {
      ops.nzFooter = null;
    }
    ops.nzData = ops.nzData || {};
    ops.nzData.getModalRef = () => modalRef;
    ops.nzData.isDialog = true;
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalRef = modalService.create(ops);
  }
  
}