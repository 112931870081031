import { FormControl } from "@angular/forms";

export class InputHelper {
  public static isNumber(event: KeyboardEvent): boolean {
    switch (event.key) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        return true;
      default:
        return false;
    }
  }

  public static isEnter(event: KeyboardEvent): boolean {
    return event.key == 'Enter';
  }

  // for U.S phone number format: +1 (323) 974-5003
  public static formatPhone(phoneStr: string): string {
    if (!phoneStr) return "";
    let text = phoneStr.replace(/[^0-9]/g, '');
    if (text.length < 10) return text;

    if (text.length > 10) {
      let part1 = text.substring(0, text.length - 10);
      let part2 = InputHelper.formatPhoneUS(text.substr(-10));
      return `+${part1} ${part2}`
    }
    return InputHelper.formatPhoneUS(text)
  }

  private static formatPhoneUS(phoneStr: string): string {
    let part1 = phoneStr.substring(0, 3);
    let part2 = phoneStr.substring(3, 6);
    let part3 = phoneStr.substring(6, 10);
    let text = '(' + part1;
    if (part1.length == 3 && part2.length > 0) {
      text += ')';
    }
    if (part2.length > 0) {
      text += ' ' + part2;
      if (part3.length > 0) {
        text += '-' + part3;
      }
    }
    return text;
  }

  public static isValidPhone(phoneStr: string) {
    var regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return regex.test(phoneStr);
  }

  public static getValuePhone(str: string): string {
    if (str === null || str === undefined) return str;
    return str.replace(/[^0-9]/g, '').substring(0, 14); // mã quốc gia 1-4 kí tự số
  }

  public static handleInputChangePhone(event, formcontrol: FormControl) {
    let text = InputHelper.formatPhone(event.target.value);
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, {emitEvent:false});
      }
    }
  }

  // isInteger = true: dữ liệu input là kiểu số nguyên (phải cắt hết 0 ở đầu)
  // mặc định: dữ liệu input là kiểu string (bao nhiêu 0 ở đầu cũng được)
  public static handleInputChangeNumberOnly(event, formcontrol: FormControl, options: {isInteger?: boolean} = {}) {
    let defaultOptions = {isInteger: false}
    let ops = Object.assign(defaultOptions, options || {});
    let text = event.target.value;
    text = text.replace(/[^0-9]/g, '');
    if (text.length == 0) {
      text = null;
    }
    if (ops.isInteger && text && text.length > 1) {
      text = text.replace(/^0+/, '');
      if (text.length == 0) {
        text = '0';
      }
    }
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, {emitEvent:false});
      }
    }
  }

  public static handleInputKeyPressNumberOnly(event: KeyboardEvent): boolean {
    return InputHelper.isNumber(event);
  }
}