import { NgModule } from '@angular/core';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MapConfig } from './config';
import { MapContainer } from './container';

@NgModule({
    imports: [
        NzSelectModule,
        NzCheckboxModule,
        CommonModule,
        FormsModule,
        NzIconModule,
        NzButtonModule,
    ],
    declarations: [
        MapConfig,
        MapContainer,
    ],
    exports: [
        MapConfig,
        MapContainer,
    ],
    providers: []
})

export class MapModule { }