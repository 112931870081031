import { UIHelper } from "@services/UIHelper";
import { BaseCompleteTaskService } from "./base.service";
import { AnswersTaskETAConfirmation } from "@wearewarp/types/data-model/types/work-queue";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { TaskService } from "../task.service";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-util";


export class CompleteTaskETAConfirmationService extends BaseCompleteTaskService {
  constructor(protected api: ApiService, private taskService: TaskService) {
    super(api);
  }
  public async completeTask(data) {
    const { jobId, answer } = data;
    if (! jobId || ! answer) {
      return UIHelper.showErr('[Complete service] Missing jobId or answer');
    }
    let type = <AnswersTaskETAConfirmation> answer.type;
    switch (type) {
      case 'driverOnTime':
        return this.updateETA(jobId, { etaTime: { to: answer?.data?.eta } });
      case 'driverLate':
        return this.markDriverLate(answer);
      case 'driverNotPickup':
        return this.markDriverNotPickup(answer);
      case 'contactFailed':
        return this.markContactFailed(answer);
    }
  }

  private markDriverLate(answer) {
    const jobId = this.taskService?.getRouteEntity()?.getId();
    const driver = this.taskService?.getRouteEntity()?.getDriver();
    const currentStop = this.taskService?.getCurrentStopEntity();
    const scheduleTime = currentStop?.getScheduleTime();
    const pickEndTime = scheduleTime ? (scheduleTime.to ?? scheduleTime.from) : null;

    let etaTime = answer?.data?.eta;
    if (!etaTime) return UIHelper.showErr('Missing ETA time');
    let duration = DateUtil.getTimeDurationFromWindow({from: pickEndTime, to: etaTime});
    let lateTime = duration ? Math.floor(duration / (1000*60)) : 0;
    let driverReason = answer?.data?.reason;
    let reason = `Driver ${Utils.getFullName(driver)} might be late ${lateTime} mins (${driverReason})`;
    this.updateETA(jobId, { etaTime: { to: answer?.data?.eta } });
    this.requestCarrierRecovery(jobId, reason);
  }

  private markDriverNotPickup(answer) {
    const jobId = this.taskService?.getRouteEntity()?.getId();
    let driverReason = answer?.data?.reason;
    let reason = `Driver didn't pick up at the last minutes (${driverReason})`;
    let url = `${environment.backendUrl}/${Const.APIURI_WORK_QUEUE}/driver-cant-pickup`;
    this.api.POST(url, { jobId, reason }).subscribe({
      next: () => {
        Log.d('Update driver not pickup successfully');
      },
      error: (e) => { Log.e(e); }
    });
  }

  private async updateETA(jobId, data) {
    const currentStop = this.taskService.getCurrentStopEntity();
    const taskIds = currentStop?.getRouteTaskIds();
    const shipmentIds = currentStop?.getShipments()?.map(sh => sh.getId()) || [];
    const etaTime = data.etaTime;
    const params = {
      etaTime,
      taskIds,
      shipmentIds
    }
    const url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/eta-time`)}`;
    this.api.PUT(url, params).subscribe({
      next: (resp) => {
        Log.d('Update ETA time successfully');
      },
      error: (e) => { Log.e(e); }
    })
  }

  private async requestCarrierRecovery(jobId, reason) {
    let url = `${environment.backendUrl}/${Const.APIURI_WORK_QUEUE}/request-recovery`;
    this.api.POST(url, { jobId, reason }).subscribe({
      next: () => {
        Log.d('Request carrier recovery successfully');
      },
      error: (e) => { Log.e(e); }
    });
  }

  private async markContactFailed(answer) {
    const jobId = this.taskService?.getRouteEntity()?.getId();
    let needRecovery = answer?.data?.needRecovery;
    if (needRecovery) {
      let reason = `Didn't answer AFTER they confirmed booking`;
      this.requestCarrierRecovery(jobId, reason);
    }
    return;
  }
}