<div class="flex flex-space-between" style="max-width: 500px; border: 1px solid #e0e0e0; padding: 8px 16px;">
  <div class="flex">
    <div class="contact-avt"><span>{{ avtCharacter }}</span></div>
    <div class="left10">
      <div><span class="highlight-text">{{ contactName || 'N/A' }}</span><span class="left5" style="color: #b7b7b7">{{ contactEntity }}</span></div>
      <div class="top5">{{ contactPhone || 'N/A' }}<i nz-icon nzType="copy" nzTheme="outline" (click)="copyToClipboard(contactPhone)" style="margin-left: 5px; cursor: pointer;"></i></div>
      <div *ngIf="contactEmail" class="top5">{{ contactEmail }}<i nz-icon nzType="copy" nzTheme="outline" (click)="copyToClipboard(contactEmail)" style="margin-left: 5px; cursor: pointer;"></i></div>
    </div>
  </div>
  <div class="phone-call-btn">
    <a href="tel:{{ contactPhone }}">
      <i class="fa fa-phone" style="font-size: 18px; color: #fff; rotate: 90deg;"></i>
    </a>
  </div>
</div>