<ng-container *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></ng-container>

<ng-container *ngIf="!isLoading && !needAutoCompleteTask">
  <div class="task-content-container">
    <task-overview [taskTitle]="taskName"></task-overview>
    <div class="top10"></div>
    <div class="body-container">
      <!-- Confirm items & log history -->
      <div class="flex flex-space-between top10">
        <div>
          <div class="label-text">{{ statusConfirmationText }}</div>
          <div *ngFor="let key of confirmItemsArr" class="confirm-item bottom8" [ngClass]="{'confirmed': isConfirmedItem(key), 'hidden': !shouldShowItem(key)}">
            <i *ngIf="!isConfirmedItem(key)" nz-icon nzType="question-circle" nzTheme="fill" style="font-size: 16px;"></i>
            <i *ngIf="isConfirmedItem(key)" nz-icon nzType="check-circle" nzTheme="fill" style="font-size: 16px;"></i>
            <span class="left10">{{ getItemLabel(key) }}</span>
          </div>
        </div>
      </div>
      <!-- Call carrier & answer box -->
      <div>
        <div>
          <div class="label-text top20">{{ remindCarrierLabel }}</div>
          <div class="desc-box top5">
            <span class="light-text" [innerHTML]="getRemindCarrierDescription()"></span>
          </div>  
        </div>
        <!-- contact carrier box -->
        <div contact-carrier-box [carrierId]="assignedCarrierId" class="top15"></div>

        <div class="top15">
          If carrier can't find the email, please 
          <span *ngIf="!isSendMailLoading && !timeCountdown" class="resend-email" (click)="onBtnResendBookingEmail()">resend Booking Confirmation email.</span>
          <span *ngIf="isSendMailLoading" nz-icon nzType="loading" nzTheme="outline"></span>
          <span *ngIf="timeCountdown" class="resend-email">resend after ({{ timeCountdown }}s)</span>
        </div>
        
        <!-- answers box -->
        <div class="answer-box top20">
          <div class="light-text">Select Carrier's response below</div>
          <div class="top10">
            <div carrier-answers-for-task-route-confirmation
              [routeEntity]="getRoute()"
              (responseChange)="onResponseChange($event)"
              (refresh)="refreshRouteData()"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="map-container">
    <div class="map-wrapper" *ngIf="isShowMap" [attr.data-live-tracking]="isEnableLiveTracking">
      <dispatch-route-map 
      *ngIf = "routeMapV2 && isVisibleMiniMap"
      [driverId]="carrierInfo.id" 
      [data]="{job: routeData}" 
      [shipments]="shipments">
      </dispatch-route-map>
      <div class="btn-open-map" (click)="openMap()">
        <img src="/assets/img/icon-open-map.svg" alt="btn-open-map">
      </div>
    </div>
    <div *ngIf="!isEnableLiveTracking" live-tracking-status [isEnableLiveTracking]="isEnableLiveTracking" >
    </div> 
    <div log-history [logs]="logHistory" [timezone]="stopTimeZone">  
    </div>
  </div>
</ng-container>

<nz-modal [nzVisible]="isVisibleMap" 
  [nzMaskClosable]="false" nzWidth="70%" 
  nzTitle="Driver Live Tracking" (nzOnCancel)="handleCancel()" 
  nzWrapClassName="map-modal"
>
  <ng-container *nzModalContent>
    <div class="map-wrapper" [attr.data-live-tracking]="true">
      <dispatch-route-map 
        [driverId]="carrierInfo.Id"
        [data]="{job: routeData}"
        [shipments]="shipments"
        [isSmallMap]="!routeMapV2"
        (onDestroy)="onBigMapDestroy()"
      ></dispatch-route-map>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>