import mapboxgl from "mapbox-gl";
import { MapboxStaticService } from "./service";
import { getInjector } from '@services/index';

export class Popup extends mapboxgl.Popup {
  private service: MapboxStaticService = getInjector().get(MapboxStaticService);

  addTo(map: mapboxgl.Map): this {
    this.service.mapElements.push(this);
    super.addTo(map);
    return this;
  }
}