<div style="height: 200px;">
    <map-container [(map)]="deliveryMap" [config]="{pitch: 0, mapStatic: true}" (mapChange)="loadMap()" mapStyle="streets-v11" [showConfig]="false"></map-container>
    <div class="switch-wrapper">
        <button 
        class="switch-type-btn" 
        [class.active]="currentMapStyle === MAP_STYLES.STREETS"
        (click)="switchMap(MAP_STYLES.STREETS)">
        Streets
      </button>
      <button 
        class="switch-type-btn"
        [class.active]="currentMapStyle === MAP_STYLES.SATELLITE" 
        (click)="switchMap(MAP_STYLES.SATELLITE)">
        Satellite
      </button>
    </div>  
    <div class="slider" *ngIf="!isSmallMap && hasHistoricalLocationData">
        <div class="container">
            <div class="right5">{{getStartTimeSlider()}}</div>
            <nz-slider [nzTooltipVisible]="'never'" style="width: 250px;" [nzStep]="1" [nzMin]="1" [nzMax]="historicalLocations?.length" (ngModelChange)="onChangeSlider($event)" [ngModel]="timeSliderValue"></nz-slider>
            <div class="left5">{{getLastTimeSlider()}}</div>
        </div>
    </div>
</div>  