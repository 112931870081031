<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="main-container" *ngIf="!isLoading" (window:resize)="onResize($event)">
  <div *ngIf="!isHasTask" class="fetch-task-container">
    <ng-container *ngIf="isTaskQueueEmpty" [ngTemplateOutlet]="tplTaskQueueEmpty"></ng-container>
  </div>
  <div class="body-container" *ngIf="isHasTask">
    <div class="route-container" [class.show-detail]="shouldShowRouteDetail">
      <nz-affix *ngIf="shouldShowRouteDetail">
        <div class="affix-route-detail" task-queue-route-detail></div>
      </nz-affix>
      <nz-affix *ngIf="!shouldShowRouteDetail">
        <button nz-button class="base-expand-btn" (click)="onchangeRouteDetailVisibility()" nz-tooltip [nzTooltipTitle]="'Route detail'">
          <i class="fa fa-fw fa-chevron-right"></i>
        </button>
      </nz-affix>
    </div>
    <div class="task-container">
      <nz-row>
        <nz-col [nzMd]="24" [nzLg]="24">
          <task-arrival-confirmation *ngIf="isTaskArrivalConfirmation" (answerCompleted)="onAnswerCompleted()"></task-arrival-confirmation>
          <task-departure-confirmation *ngIf="isTaskDepartureConfirmation" (answerCompleted)="onAnswerCompleted()"></task-departure-confirmation>
          <task-route-confirmation *ngIf="isTaskRouteConfirmation" (answerCompleted)="onAnswerCompleted()" (autoCompleteTask)="autoCompleteTask()"></task-route-confirmation>
          <task-otp-confirmation *ngIf="isTaskOTPConfirmation" (answerCompleted)="onAnswerCompleted()" (autoCompleteTask)="autoCompleteTask()"></task-otp-confirmation>
          <task-eta-confirmation *ngIf="isTaskETAConfirmation" (answerCompleted)="onAnswerCompleted()" (autoCompleteTask)="autoCompleteTask()"></task-eta-confirmation>
        </nz-col>
      </nz-row>
    </div>
  </div>
  <div *ngIf="isHasTask && shouldShowNoteBox" class="note-box" note-box [jobId]="jobId"></div>
  <div *ngIf="isHasTask && shouldShowMessageBox" class="driver-chat-box" driver-chat-box [jobId]="jobId" [driverInfo]="driverInfo"></div>
  <div class="footer" *ngIf="isHasTask">
    <div class="action-box">
      <a (click)="onBtnSkipTask()">Skip task</a>
      <button nz-button nzType="primary" nzTheme="outline" [nzLoading]="onProgress" [disabled]="!canCompleteTask" (click)="onBtnCompleteTask()">Complete task</button>
    </div>
  </div>
</div>

<ng-template #tplTaskQueueEmpty>
  <div class="tpl-no-task">
    <div>
      <img src="assets/img/no-task-icon.png" alt="No task" style="width: 130px;">
    </div>
    <div class="no-task-header top20">No Task for you right now</div>
    <div class="no-task-desc top10">Hold on, as new tasks can be assigned anytime.</div>
  </div>
</ng-template>
