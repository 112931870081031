<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div *ngIf="!isLoading" class="container">
  <div class="text-desc">Ask "Could you help me to confirm the following information?"</div>
  <div class="steps-container top10">
    <nz-steps nzDirection="vertical" nzSize="small">
      <nz-step [nzTitle]="confirmLTStepTitle" [nzDescription]="loadtenderStep" [nzStatus]="isConfirmedLoadtender ? 'finish' : 'wait'" class="bottom10" *ngIf="showLoadTenderStep"></nz-step>
      <nz-step [nzTitle]="confirmReadyStepTitle" [nzDescription]="confirmReadyStep" [nzStatus]="isConfirmedReady ? 'finish' : 'wait'" class="bottom10"></nz-step>
      <nz-step [nzTitle]="assignDriverStepTitle" [nzDescription]="assignDriverStep" [nzStatus]="isAssignedDriver && isConfirmedDriverReady ? 'finish' : 'wait'"></nz-step>
    </nz-steps>

    <ng-template #loadtenderStep>
      <div class="step-info">
        <div class="flex">
          <img src="assets/svg/document_scanner.svg" alt="Accept the Load Tender">
          <div class="text-label left15">Accept the Load Tender</div>
        </div>
      </div>
    </ng-template>

    <ng-template #confirmReadyStep>
      <div class="step-info">
        <div class="flex flex-space-between">
          <div style="align-content: center;">
            <img src="assets/svg/local_shipping.svg" alt="Confirm Ready">
            <span class="text-label left15">{{getDisplayEquipment()}}</span>
          </div>
          <button *ngIf="!isConfirmedReady" nz-button nzTheme="outline" [nzLoading]="confirmReadyLoading" (click)="onBtnConfirmReady()">Confirm Ready</button>
        </div>
        <div class="top20">
          <div nz-row *ngFor="let item of itemArr" [nzGutter]="[16,16]">
            <div nz-col [nzSpan]="4" class="light-text">{{ getItemLabel(item) }}</div>
            <div nz-col [nzSpan]="20" class="item-value">{{ getItemValue(item) }}</div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #assignDriverStep>
      <div class="step-info">
        <div class="flex flex-space-between" >
          <div style="align-content: center;">
            <img src="assets/svg/assignment_id.svg" alt="Assign Driver" style="padding-bottom: 5px;">
            <span class="text-label left15">{{ confirmDriverTitle }}</span>
            <span class="text-required" *ngIf="assignDriverTeamRequired">Team required</span>
          </div>
          <div class="group-btn">
            <button nz-button nzTheme="outline" (click)="onBtnAssignDriver()">
              {{ assignDriverTitle }}
            </button>
            <button *ngIf="shouldShowConfirmDriverBtn" nz-button nzTheme="outline" (click)="onConfirmDriverReady()" [nzLoading]="confirmDriverReadyLoading">
              Confirm ready
            </button>
          </div>
        </div>
        <div *ngIf="isAssignedDriver" class="top16" >
          <ng-container *ngIf="!assignDriverTeamRequired">
            <div class="driver-info">
              <div class="driver-name">
                Driver: {{ driverNameAndPhone }}
              </div>
              <div class="driver-location">
                Location: {{ driverLocation }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="assignDriverTeamRequired">
            <div>1st Driver: {{ driverNameAndPhone }}, {{ driverLocation }}</div>
            <div>2nd Driver: {{ secondaryDriverNameAndPhone }}, {{ secondaryDriverLocation }}</div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="text-desc bottom20">{{ remindTitle }}</div>
</div>