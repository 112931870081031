<div class="live-tracking-box" [ngClass]="isEnableLiveTracking ? 'enable-theme' : 'disable-theme'">
  <div class="center-children">
    <span><img src="/assets/svg/share_location.svg" class="right10" [ngClass]="isEnableLiveTracking ? '' : 'tracking-off-icon'"></span>
    {{ liveTrackingDesc }}
  </div>
  <div *ngIf="isEnableLiveTracking">
    <!-- <button nz-button nzType="link" (click)="onChangeMapVisible()" >
      <span style="font-size: 15px; color: #001ee2;">{{ isVisible ? 'Close Map' : 'See On Map' }}</span>
      <span class="left10"><img src="/assets/img/directions.png" class="direction-icon"></span>
    </button> -->
  </div>
</div>