export const PIN_ICON_MAPPING = {
    red: { x: 0, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    green: { x: 40, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    blue: { x: 80, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    orange: { x: 120, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    redGreen: { x: 0, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    greenBlue: { x: 40, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    blueOrange: { x: 80, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    orangeRed: { x: 120, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
};

export const LOCATION_PIN_ICON_MAPPING = {
    red: { x: 0, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    green: { x: 40, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    blue: { x: 80, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    orange: { x: 120, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    gray: { x: 160, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    redUp: { x: 0, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    greenUp: { x: 40, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    blueUp: { x: 80, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    orangeUp: { x: 120, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    grayUp: { x: 160, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
};

export const DRIVER_PIN_ICON_MAPPING = {
    "0": { x: 0, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "1": { x: 40, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "2": { x: 80, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "3": { x: 120, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "4": { x: 160, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "5": { x: 200, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
};