import { Const } from "@const/Const";
import { Job, VehicleType } from "@wearewarp/types/data-model";
import StopEntity from "./StopEntity";
import { RouteService } from "../services/route.service";


export default class RouteEntity {
  private data: Job;
  private stops: StopEntity[] = [];
  private routeService: RouteService;

  public async init (data) {
    this.data = data;
    await this.initStops();
    return this;
  }

  public setRouteService(routeService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  private async initStops() {
    if (!this.data.stops) {
      console.error(`Stops Data not found!`);
      return;
    }
    this.stops = await Promise.all(this.data.stops.map(async (stop, index) => (await new StopEntity().setIndex(index).setRouteService(this.routeService).init(stop))))
  }

  public getData() {
    return this.data;
  }

  public getCode() {
    return this.data.code || ""
  }

  public getStatus() {
    return this.data.status || Const.JobStatus.created
  }

  public getId() {
    return this.data.id
  }

  public getCarrier() {
    return this.data.carrier
  }

  public getCarrierId() {
    return this.data.assignedCarrier?.carrierId;
  }

  public getDriver() {
    return this.data.driver
  }

  public getDriverId() {
    return this.data.assignedDriver?.driverId;
  }

  public getStops() {
    return this.stops
  }

  public getStopById(stopId) {
    return this.stops.find(stop => stop.getId() === stopId);
  }

  public getShipments() {
    const shipmentIds = this.data.shipments.map(it => it.id);
    return shipmentIds.map(shipmentId => this.routeService.getShipmentById(shipmentId));
  }

  public getShipmentItems() {
    let shipments = this.getShipments();
    return shipments.map(it => it.getItems()).flat();
  }

  public getRequiredVehicle(): VehicleType {
    return this.data.requiredVehicle
  }

  public getConfirmLoadtenderStatus() {
    return this.data?.assignedCarrier?.loadTenderStatus;
  }

  public getConfirmReadyStatus() {
    return this.data?.assignedCarrier?.isReady || false;
  }

  public getAssignedDriver() {
    return this.data.assignedDriver;
  }

  public getAssignedSecondaryDrivers() {
    return this.data.assignedSecondaryDrivers;
  }

  public getConfirmDriverStatus() {
    return this.data?.assignedDriver?.isReady || false;
  }

  public getAcceptedLoadtenderBy() {
    return this.data?.assignedCarrier?.acceptedLoadTenderBy;
  }

  public getConfirmedReadyBy() {
    return this.data?.assignedCarrier?.confirmedReadyBy;
  }

  public getConfirmedDriverReadyBy() {
    return this.data?.assignedDriver?.confirmedReadyBy;
  }

  public getPickDropCity() {
    let stops = this.getStops();
    let pickStop = stops?.[0];
    let dropStop = stops?.[stops.length - 1];
    return {
      pickCity: pickStop?.getData()?.info?.addr?.city,
      dropCity: dropStop?.getData()?.info?.addr?.city
    }
  }
}