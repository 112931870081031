<div class="task-content-container">
  <task-overview [taskTitle]="taskName"></task-overview>
  <div class="top10"></div>
  <div class="task-following">
    <div class="flex flex-space-between top10">
      <div class="task-following-title">{{ getTaskFollowingTitle() }}</div>
    </div>
    <div *ngIf="isEnableLiveTracking" class="top20">
      <nz-steps [nzCurrent]="stepIndex" nzDirection="vertical" nzSize="small">
        <nz-step [nzTitle]="getStepTitle(1)" [nzDescription]="tplCallAndUpdateDriverStatus"></nz-step>
      </nz-steps>
    </div>
    <div *ngIf="!isEnableLiveTracking" class="top20">
      <nz-steps [nzCurrent]="stepIndex" nzDirection="vertical" nzSize="small">
        <nz-step [nzTitle]="getStepTitle(1)" [nzDescription]="tplCallAndUpdateDriverStatus"></nz-step>
        <nz-step [nzTitle]="getStepTitle(2)" [nzDescription]="tplRemindDriver" *ngIf="shouldRemindDriver"></nz-step>
      </nz-steps>
    </div>

    <ng-template #tplCallAndUpdateDriverStatus>
      <div class="top10">
        <div *ngIf="shouldContactDriver" contact-driver-box [contactInfo]="driverInfo"></div>
        <div *ngIf="!shouldContactDriver" contact-carrier-box [carrierId]="carrierId"></div>
        <div class="top10"><span style="color: #52525b">Select {{contactEntity}}'s Response below</span></div>
        <div
          answers-for-task-eta-confirmation
          class="top10"
          [contactEntity]="contactEntity"
          (responseChange)="onResponseChange($event)"
        ></div>
      </div>
    </ng-template>

    <ng-template #tplRemindDriver>
      <div class="flex top10">
        <div send-tracking-link-btn [routeData]="routeData" (remindedDriver)="onRemindDriverCompleted($event)"></div>
      </div>
    </ng-template>
  </div>
</div>
<div class="map-container">
  <div class="map-wrapper" *ngIf="isShowMap" [attr.data-live-tracking]="isEnableLiveTracking">
    <dispatch-route-map 
    *ngIf = "routeMapV2 && isVisibleMiniMap"
    [driverId]="driverInfo.id" 
    [data]="{job: routeData}" 
    [shipments]="shipments">
    </dispatch-route-map>
    <div class="btn-open-map" (click)="openMap()">
      <img src="/assets/img/icon-open-map.svg" alt="btn-open-map">
    </div>
  </div>
  <div *ngIf="!isEnableLiveTracking" live-tracking-status [isEnableLiveTracking]="isEnableLiveTracking" >
  </div>
  <div log-history [logs]="logHistory" [timezone]="stopTimeZone">  
  </div>
</div>

<nz-modal [nzVisible]="isVisibleMap" 
  [nzMaskClosable]="false" nzWidth="70%" 
  nzTitle="Driver Live Tracking" (nzOnCancel)="handleCancel()" 
  nzWrapClassName="map-modal"
>
  <ng-container *nzModalContent>
    <div class="map-wrapper" [attr.data-live-tracking]="true">
      <dispatch-route-map 
        [driverId]="driverInfo.Id"
        [data]="{job: routeData}"
        [shipments]="shipments"
        [isSmallMap]="!routeMapV2"
        (onDestroy)="onBigMapDestroy()"
      ></dispatch-route-map>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
