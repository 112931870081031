<div class="driver-status-container">
  <nz-radio-group [(ngModel)]="driverStatus">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24 / displayStatusArr.length" *ngFor="let key of displayStatusArr">
        <div class="box-status-item"
          nz-tooltip
          [nzTooltipTrigger]="'click'"
          [nzTooltipTitle]="getTemplate(key)"
          [nzTooltipTitleContext]="{ $implicit: key }"
          [nzTooltipPlacement]="'top'"
          [nzTooltipColor]="'white'"
          [(nzTooltipVisible)]="visibleTemplate[key]"
          nzTooltipOverlayClassName="tooltip-info"
          style="cursor: pointer;"
        >
          <div class="flex flex-space-between">
            <div class="label-text">{{ getLabel(key)}}</div>
            <div><label nz-radio [nzValue]="getValue(key)" [nzDisabled]="!shouldEnableRadio(key)"></label></div>
          </div>
          <div  class="text-desc top20" style="white-space: pre-line;" [innerHTML]="getDesc(key)"></div>
        </div>
      </div>
    </div>
  </nz-radio-group>

  <ng-template #tplMarkDriverNotArrived let-key>
    <div class="tpl-mark-not-arrived-box">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <div class="flex flex-space-between top10" style="width: 300px; align-items: center;">
        <div>ETA</div>
        <div class="left10">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpETA" nzShowNow="false"></nz-date-picker>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #tplMarkDriverArrived let-key>
    <div class="tpl-mark-arrived-box">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <div class="flex center-children top10">
        <div>Actual arrival time</div>
        <div class="left10">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpATA" nzShowNow="false"></nz-date-picker>
        </div>
      </div>
      <!-- <button nz-button nzTheme="outline" style="padding: 4px 48px;" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Mark Driver as Arrived</button> -->
    </div>
  </ng-template>

  <ng-template #tplMarkDriverHasLeft let-key>
    <div class="tpl-mark-departured-box">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <div class="flex center-children top10">
        <div>Actual arrival time</div>
        <div class="left10">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpATA" nzShowNow="false"></nz-date-picker>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #tplMarkDriverNotFinishedLoading let-key>
    <div>
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <nz-row [nzGutter]="16" class="top10" style="align-items: center;">
        <nz-col [nzSpan]="8">Delay reason</nz-col>
        <nz-col [nzSpan]="16">
          <textarea nz-input placeholder="E.g taking time to park" [(ngModel)]="tmpNoteDelayReason" style="width: 100%;"
            [nzAutosize]="{ minRows: 1, maxRows: 3}"
          ></textarea>
        </nz-col>
      </nz-row>
      <nz-row [nzGutter]="16"  class="top10" style="align-items: center;">
        <nz-col [nzSpan]="8">Estimate Departure Time</nz-col>
        <nz-col [nzSpan]="16">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpEstimateDepartureTime" nzShowNow="false" style="width: 100%"></nz-date-picker>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>

  <ng-template #tplMarkDriverDeparted let-key>
    <div>
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <div class="flex center-children top10">
        <div>Departure time</div>
        <div class="left20">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpDepartureTime" nzShowNow="false" style="width:200px"></nz-date-picker>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #tplMarkContactFailed let-key>
    <div class="tpl-mark-contact-failed-box">
      <div class="flex flex-space-between" style="align-items: center;">
        <div style="font-weight: 500;">Enter Note</div>
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateDriverStatus(key)" (click)="updateDriverStatus(key)">Confirm</button>
      </div>
      <div class="top15">
        <textarea nz-input placeholder="e.g I have called 3 times but got no response" 
          [(ngModel)]="tmpNoteContactFailed" style="width: 330px;"
          [nzAutosize]="{ minRows: 1, maxRows: 3}"
        ></textarea>
      </div>
    </div>
  </ng-template>
  
</div>