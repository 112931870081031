<div class="lp-form-title">
  Two-Factor Authentication
</div>

<div class="lp-form-description">
  Open your authentication app and enter the code for Warp
</div>

<form [formGroup]="formInput" nz-form>
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label">{{getLabel(key)}}</div>
    <nz-form-item class="form-value">
      <ng-container [ngSwitch]="key">
        <input *ngSwitchCase="'otp'" #inputCode nz-input
          [formControlName]="key" type="text" name="otp"
          [placeholder]="getPlaceHolder(key)"
          (input)="onInputChanged($event, key)"
          (keypress)="onInputKeyPress($event, key)"
          (keyup)="onOtpKeyUp($event)"
        />
      </ng-container>
    </nz-form-item>
  </ng-container>
</form>

<button nz-button class="lp-button-border submit" [disabled]="!canVerify || onProgress" (click)="onBtnVerify()">
  <i *ngIf="onProgress" class="ic-right" nz-icon nzType="loading" nzTheme="outline"></i>ENTER
</button>
