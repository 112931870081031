<div class="flex flex-space-between" style="max-width: 500px; border: 1px solid #e0e0e0; padding: 8px 16px;">
  <div>
    <div class="highlight-text">{{ carrierName }}<span class="left5" style="color: #b7b7b7; font-style: italic;">({{ mcDotTxt }})</span></div>
    <div class="top15">
      <div *ngFor="let contact of displayInfos.contacts || []" class="bottom10">
        <div class="flex">
          <div><span class="user-icon" nz-icon nzType="user" nzTheme="outline"></span><span class="left10">{{ contact.fullName || 'N/A' }}</span></div>
          <div class="left15">{{ contact.phone || 'N/A' }}<i nz-icon nzType="copy" nzTheme="outline" (click)="copyToClipboard(contact.phone)" style="margin-left: 5px; cursor: pointer;"></i></div>  
        </div>
        <div *ngIf="contact.email" class="top5">{{ contact.email }}<i nz-icon nzType="copy" nzTheme="outline" (click)="copyToClipboard(contact.email)" style="margin-left: 5px; cursor: pointer;"></i></div>
      </div>  
    </div>
  </div>
  <div class="phone-call-btn">
    <a href="tel:{{ displayInfos?.contacts?.[0].phone }}">
      <i class="fa fa-phone" style="font-size: 18px; color: #fff; rotate: 90deg;"></i>
    </a>
  </div>
</div>