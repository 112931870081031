export const PALLETS = [
    [38, 70, 83], // 264653
    [231, 111, 81], // e76f51
    [42, 156, 143], // 2a9d8f
    [193, 18, 31], // c1121f
    [40, 54, 24], // 283618
    [3, 5, 94], // 03045e
    [120, 0, 0], // 780000
    [53, 53, 53], // 353535
    [0, 120, 182], // 0077b6
]