import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

/**
 * Service to manage task components visibility
 */

@Injectable()
export class TaskComponentsVisibiltyService {
  public shouldShowRouteDetail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  changeRouteDetailVisibility() {
    this.shouldShowRouteDetail.next(!this.shouldShowRouteDetail.getValue());
  }
}