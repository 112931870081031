import { ApiService } from "@services/api.service";
import { environment } from "@env/environment";

export class DataorchService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.dataorchUrl
    }

    public getJobTasks(id) {
        const url = `${this.baseUrl}/job/${id}/tasks`
        return this.api.GET(url);
    }
}