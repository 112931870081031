import { Shipment } from "@wearewarp/types/data-model";
import { RouteService } from "../services/route.service";
import { Const } from "@const/Const";


export default class ShipmentEntity {
  private data: Shipment;
  private routeService: RouteService;

  setRouteService(routeService: RouteService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  init(data: Shipment) {
    this.data = data;
    return this;
  }

  getData() {
    return this.data;
  }

  getId() {
    return this.data.id;
  }

  getCode() {
    return this.data.code;
  }

  getWarpId() {
    return this.data.warpId;
  }

  getDisplayWarpId() {
    return `S-${this.data.warpId}`;
  }

  getClientId() {
    return this.data.clientId;
  }

  getClient() {
    return this.data.metadata?.client;
  }

  getItems() {
    return this.data.itemIds.map(itemId => this.routeService.getShipmentItemById(itemId))
  }

  getPickInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.RouteTaskType.PICKUP);
  }

  getDropInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.RouteTaskType.DROPOFF);
  }
}