import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, Renderer2, ViewContainerRef } from "@angular/core";
import { DeliveryMap } from "@app/components/map";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { SocketService } from '@services/socket.service';
import { LatLng } from "@wearewarp/types/data-model";
import { Subscription } from "rxjs";
import _ from "underscore";
import { SocketEvent } from "@app/enum";
import GPSFilter from "../services/gps-filter.service";
import { DriverLocationService } from "../services/driver-location";
import { DataorchService } from "../services/dataorch.service";
import { PopupLocation } from "../route-map-popup";
import { DateUtil } from "@services/date-util";
import { Popup } from "@app/components/mapbox-static/Popup";
import { Marker } from "@app/components/mapbox-static/Marker";
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { FormsModule } from "@angular/forms";
import { UIHelper } from "@services/UIHelper";
import { MapModule } from "@app/components/map/module";
import { NgIf } from "@angular/common";

const CustomInputs = [
    NzSliderModule,
    FormsModule,
    MapModule,
    NgIf
];

@Component({
    selector: "dispatch-route-map",
    templateUrl: "./index.html",
    standalone: true,
    imports: [CustomInputs],
    styleUrls: ["./index.scss"],
})
export class DispatchRouteMap extends BaseComponent {
    public readonly MAP_STYLES = {
        STREETS: 'streets-v11',
        SATELLITE: 'satellite-streets-v11' 
    } as const;
    public currentMapStyle: typeof this.MAP_STYLES[keyof typeof this.MAP_STYLES] = this.MAP_STYLES.STREETS;

    deliveryMap: DeliveryMap
    gpsFilter: GPSFilter
    dataorch: DataorchService
    timeMarks: any;
    timeSliderValue: number = 0;

    _job: any = null
    get job() {
        return this._job
    }

    @Input() driverId: string;    // app cũ khi unassign driver vẫn gửi location nên cần filter event theo driverId
    @Input() isSmallMap: boolean = true;
    @Output() onDestroy = new EventEmitter<void>();
    hasHistoricalLocationData: boolean = false;

    private driverLocationSubscriptionNew: Subscription;
    /**
     * @deprecated will be removed shortly
     */
    private driverLocationSubscription: Subscription;
    constructor(private socketService: SocketService,
        private driverLocationService: DriverLocationService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2
    ) {
        super();
        this.dataorch = new DataorchService(this.api)
        this.timer = setInterval(() => {
            this.checkLiveUpdate()
        }, 15000)

    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
        this.onDestroy?.emit();
    }

    _data: any = null
    @Input() set data(v: { job?: any, tasks?: any }) {
        this._data = v
        this._job = v.job
        this._tasks = v.tasks
        this.loadMap()
        this.getRouteData()
        this.getLocations()
        this.registerRealTimeLocation()

        if (!v.tasks && v.job?.id) {
            this.loadTasks(v.job.id)
        }
    }

    loadTasks(jobId) {
        this.dataorch.getJobTasks(jobId).subscribe((res) => {
            this._tasks = res
            this.loadMap()
        })
    }

    _tasks: any[] = []
    get tasks() {
        return this._tasks
    }

    @Input() routeData
    @Input() shipments
    isLoading: boolean = false
    _historicalLocations: any[] = []
    _locations: any[] = []

    timer: any = null

    get locations() {
        return this._locations
    }
    set locations(v) {
        this._locations = _.sortBy(v, 'ts')
        if (v?.length && !this._currentLocation) {
            const latest = this._locations[v.length - 1]
            if (latest.ts > Date.now() - 3600 * 1000)
                this.currentLocation = latest
        }
        this.deliveryMap?.loadDriverLocations(this._locations, true)
    }
    get historicalLocations() {
        return this._historicalLocations
    }
    set historicalLocations(v) {
        this._historicalLocations = v
        if (v?.length) {
            const latest = this._historicalLocations[0]
            if (!this._locations?.length) {
                this.locations = [latest]
            }
        }
        this.deliveryMap?.loadHistoricalDriverLocations(this._historicalLocations, true)
    }

    _currentLocation: any = null
    get currentLocation() {
        return this._currentLocation
    }
    set currentLocation(v) {
        this._currentLocation = v
        this.deliveryMap?.loadLiveTrackingLocations([this._currentLocation])
    }

    switchMap(style: typeof this.MAP_STYLES[keyof typeof this.MAP_STYLES]) {
        if (this.currentMapStyle === style) return;
        this.currentMapStyle = style;
        if (this.deliveryMap?.map) {
            this.deliveryMap.map.setStyle(`mapbox://styles/mapbox/${this.currentMapStyle}`);
        }
    }

    loadMap() {
        this.deliveryMap?.loadJobs([
            {
                job: this._job,
                tasks: this._tasks,
                line: this.routeData?.line,
                shipments: this.shipments
            }
        ], true)
        if (this._currentLocation) {
            this.deliveryMap?.loadLiveTrackingLocations([this._currentLocation])
        }
        this.deliveryMap?.fitBoundsToJobs()
    }

    private get isJobInProgress(): boolean {
        return this.job?.status == Const.JobStatus.inProgress;
    }

    private registerRealTimeLocation() {
        if (!this.isJobInProgress) {
            this.stopRealTimeLocation()
        } else {
            this.startRealtimeLocation()
        }
    }

    private stopRealTimeLocation() {
        this.driverLocationSubscription?.unsubscribe()
        this.driverLocationSubscriptionNew?.unsubscribe();
    }

    private startRealtimeLocation() {
        this.gpsFilter = new GPSFilter();
        this.driverLocationSubscription = this.socketService.subscribeEvent(SocketEvent.updateDriverLocation, data => {
            if (!data.jobIds.includes(this.job.id)) return;
            if (data.authUser?.collection == 'drivers' && data.authUser?.id != this.driverId) return;   // app cũ driver đã bị unassigned rồi
            const { location } = data
            if (!location) return
            this.updateDriverLocation({
                location: <any>data.location,
                ts: new Date(data.when).getTime()
            })
        })

        this.driverLocationSubscriptionNew = this.driverLocationService.subscribeDriverLocation(data => {
            if (data.activeJobId != this.job.id) return;
            this.updateDriverLocation({ location: data.location, ts: new Date(data.when).getTime() });
        });


        //
    }

    pushLatestLocation(loc) {
        this.currentLocation = loc
        if (!this._locations) {
            this._locations = []
        }
        this._locations.push(loc)
        //nếu 2 điểm cuối trùng nhau thì bỏ qua
        const lastLocation = this._locations?.[this._locations.length - 2];
        if (lastLocation?.location?.latitude == loc?.location?.latitude
            && lastLocation?.location?.longitude == loc?.location?.longitude) {
            return
        }

        this.deliveryMap?.loadDriverLocations(this._locations, true)
    }

    private getEstSpeed() {
        //get avg speed from last 10 locations
        const locationHistories = this._locations.slice(-10);
        let avgSpeed = 0;
        for (let i = 0; i < locationHistories.length; i++) {
            const history = locationHistories[i];
            avgSpeed += Number(history?.location?.speed || 3); //default 3m/s
        }
        avgSpeed = avgSpeed / locationHistories.length;
        return avgSpeed || 3;
    }

    public updateDriverLocation(lastKnownLocation: { location: LatLng, ts: number }) {
        if (!lastKnownLocation?.location) return;
        this.gpsFilter.setSpeed(this.getEstSpeed() || 16);
        const nextLocation = this.gpsFilter.process(
            Number(lastKnownLocation.location.latitude),
            Number(lastKnownLocation.location.longitude),
            (<any>lastKnownLocation.location).accuracy || 1, //FIXME: accuracy
            new Date(lastKnownLocation.ts).getTime()
        );
        if (nextLocation.latitude) {
            // console.log('updateDriverLocation', nextLocation, lastKnownLocation.location, this.getEstSpeed())
            lastKnownLocation.location.latitude = nextLocation.latitude;
            lastKnownLocation.location.longitude = nextLocation.longitude;
        }
        this.pushLatestLocation(lastKnownLocation)
    }

    getRouteData() {
        if (!this._job?.id) return
        this.isLoading = true;
        this.api.GET(`${Const.APIURI_JOBS}/${this.job.id}/route-data`).subscribe(
            (resp) => {
                this.isLoading = false;
                this.routeData = resp?.data?.routeData || null;
                const currentLocation = resp?.data?.lastKnownLocation
                if (resp?.data?.lastKnownLocation?.receivedTs) {
                    currentLocation.ts = resp?.data?.lastKnownLocation?.receivedTs
                }
                this.currentLocation = currentLocation
                this.loadMap();
            },
            (err) => {
                this.isLoading = false;
                UIHelper.showErr(err);
                this.loadMap();
            }
        );
    }

    checkLiveUpdate() {
        if (!this.isJobInProgress) return
        if (this.currentLocation?.ts && this.currentLocation.ts > Date.now() - 30000) {
            this.deliveryMap?.loadLiveTrackingLocations([this.currentLocation]);
            return
        }
        if (!environment.eventUrl) return;
        let url = `${environment.eventUrl}/delivery/JOB_${this.job.id}/LOCATION?limit=1`;
        this.api.GET(url).subscribe(resp => {
            if (resp && resp.length > 0) {
                const latest = resp[0]
                if (!this.currentLocation?.ts || this.currentLocation.ts < latest.ts) {
                    this.pushLatestLocation(latest)
                }
            }
        })
    }

    getLocations() {
        if (!this.job.id || !environment.eventUrl) return;
        let url = `${environment.eventUrl}/delivery/JOB_${this.job.id}/LOCATION`;
        this.api.GET(url).subscribe(resp => {
            if (resp && resp.length > 0) {
                this.historicalLocations = resp;
                this.timeSliderValue = this.historicalLocations.length;
                this.hasHistoricalLocationData = true;
                this.cloneHistoricalLocations = this.historicalLocations.slice().reverse();
                // if(this.isSmallMap) {
                //     const point = this.historicalLocations[0];
                //     this.addPopup(point, this.isSmallMap);
                // }
            } else {
                this.hasHistoricalLocationData = false;
            }
        },
            err => {
            })
    }

    cloneHistoricalLocations: any[] = [];
    popup: Popup;
    marker: Marker;
    onChangeSlider(value) {
        const point = this.cloneHistoricalLocations[value - 1];
        const nextPoint = this.cloneHistoricalLocations[value];
        this.removePopupMarker();
        if (point && nextPoint) {
            this.addPopup(point, this.isSmallMap);
            this.addMarker(point, nextPoint);
        }
    }

    addPopup(point, isSmallMap = true) {
        const pointLong = point.location.longitude;
        const pointLat = point.location.latitude;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PopupLocation);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        const instance = componentRef.instance;
        instance.infoLocation = point;
        instance.isSmallMap = isSmallMap;
        const markerElement = componentRef.location.nativeElement;
        this.popup = new Popup({ maxWidth: 'none', closeButton: false, className: 'popup-location' })
            .setDOMContent(markerElement)
            .setLngLat([pointLong, pointLat])
            .addTo(this.deliveryMap.map);
    }

    addMarker(point, nextPoint) {
        if (!nextPoint) nextPoint = point;
        const pointLong = point.location.longitude;
        const pointLat = point.location.latitude;
        const el = document.createElement('div');
        el.style.backgroundImage = 'url(assets/svg/navigation.svg)';
        el.style.width = `30px`;
        el.style.height = `30px`;
        el.style.backgroundSize = '100%';
        el.style.position = 'absolute';
        el.style.zIndex = '1';
        const rotate = this.bearingBetweenLocations(point.location, nextPoint.location);
        this.marker = new Marker({ element: el, rotation: rotate })
            .setLngLat([pointLong, pointLat])
            .addTo(this.deliveryMap.map);
    }

    removePopupMarker() {
        if (this.popup) {
            this.popup.remove();
        }
        if (this.marker) {
            this.marker.remove();
        }
    }

    bearingBetweenLocations(location1, location2) {
        if (!location2) return 0;
        const PI = Math.PI;
        const lat1 = location1.latitude * PI / 180;
        const lon1 = location1.longitude * PI / 180;
        const lat2 = location2.latitude * PI / 180;
        const lon2 = location2.longitude * PI / 180;
        const dLon = lon2 - lon1;
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        let brng = Math.atan2(y, x) * 180 / PI;
        brng = (brng + 360) % 360;
        return brng;
    }

    getStartTimeSlider() {
        if (!this.cloneHistoricalLocations.length) return '';
        const ts = this.cloneHistoricalLocations[0].ts;
        return DateUtil.displayLocalTime(ts, Intl.DateTimeFormat().resolvedOptions().timeZone, 'hh:mm A')
    }

    getLastTimeSlider() {
        if (!this.cloneHistoricalLocations.length) return '';
        const ts = this.cloneHistoricalLocations[this.cloneHistoricalLocations.length - 1].ts;
        return DateUtil.displayLocalTime(ts, Intl.DateTimeFormat().resolvedOptions().timeZone, 'hh:mm A')
    }
}  