<div>
  <task-overview [taskTitle]="taskName"></task-overview>
  <div class="top10">
    <div live-tracking-status [isEnableLiveTracking]="isEnableLiveTracking" (changeMapVisiable)="changeMapVisiable()"></div>
  </div>
  <div class="task-following">
    <div class="flex flex-space-between top10">
      <div class="task-following-title">{{ getTaskFollowingTitle() }}</div>
      <div log-history [logs]="logHistory" [timezone]="stopTimeZone"></div>
    </div>
    <div *ngIf="isEnableLiveTracking">
      <div driver-status [markOptions]="getDriverResponseOptions()" (driverResponseChange)="onDriverResponseChange($event)" class="top20"></div>
    </div>
    <div *ngIf="!isEnableLiveTracking" class="top20">
      <nz-steps [nzCurrent]="stepIndex" nzDirection="vertical" nzSize="small">
        <nz-step [nzTitle]="getStepTitle(1)" [nzDescription]="tplCallAndUpdateDriveStatus"></nz-step>
        <nz-step [nzTitle]="getStepTitle(2)" [nzDescription]="tplRemindDriver" *ngIf="shouldRemindDriver"></nz-step>
      </nz-steps>
     
    </div>
  </div>

  <div class="map-wrapper" *ngIf="isShowMap">
    <dispatch-route-map 
      [driverId]="driverInfo.id"
      [data]="{job: routeData}"
      [shipments]="shipments"
    ></dispatch-route-map>
  </div>

  <ng-template #tplCallAndUpdateDriveStatus>
    <div class="top10">
      <div contact-driver-box [contactInfo]="mainContactInfo"></div>
      <div class="top20" location-instruction [instructions]="locationInstruction"></div>
      <div class="top10"><span style="color:#52525B">Select Driver's Response below</span></div>
      <div driver-status [contactInfo]="mainContactInfo" [markOptions]="getDriverResponseOptions()" (driverResponseChange)="onDriverResponseChange($event)" class="top10"></div>  
    </div>
  </ng-template>

  <ng-template #tplRemindDriver>
    <div class="flex top10">
      <div send-tracking-link-btn [routeData]="routeData" (remindedDriver)="onRemindDriverCompleted($event)"></div>
    </div>
  </ng-template>
</div>