import { Const } from "@const/Const";
import { Task } from "@wearewarp/types/data-model";
import { RouteService } from "../services/route.service";

/**
 * Task của route (là pickup hoặc dropoff)
 */
export default class RouteTaskEntity {
  private data: Task;
  private routeService: RouteService;

  setRouteService(routeService: RouteService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  init(task: Task) {
    this.data = task;
    return this
  }

  getId() {
    return this.data.id;
  }

  getStatus() {
    return this.data.status || Const.RouteTaskStatus.created
  }

  getArrivedTime() {
    if (![Const.RouteTaskStatus.arrived, Const.RouteTaskStatus.succeeded, Const.RouteTaskStatus.failed].includes(<any>this.getStatus())) return;
    const log = this.data.statusChangeLog[Const.RouteTaskStatus.arrived]
    let changeWhen = log?.changeWhen ?? log?.when // fix bug changeWhen null
    //trường hợp không có thông tin arrived time (do đổi status trực tiếp sang completed),
    //sẽ lấy thời gian depart để hiển thị cho arrived. Coi như đến rồi đi luôn.
    if (!changeWhen) return this.getDepartedTime();
    return changeWhen
  }

  getDepartedTime() {
    if (![Const.RouteTaskStatus.succeeded, Const.RouteTaskStatus.failed].includes(<any>this.getStatus())) return;
    //nếu status hiện tại là failed. thì lấy departed time là thời gian đổi sang failed
    let log;
    switch (this.getStatus()) {
      case Const.RouteTaskStatus.failed:
        log = this.data.statusChangeLog[Const.RouteTaskStatus.failed]
        break;
      case Const.RouteTaskStatus.succeeded:
        log = this.data.statusChangeLog[Const.RouteTaskStatus.succeeded]
        break;
      default:
    }
    return log?.changeWhen ?? log?.when
  }
  getETA() {
    return this.data.etaTime
  }

  getShipmentId() {
    return this.data.shipmentId
  }

  getType() {
    return this.data.type
  }

  getRefNums() {
    return this.data.info?.refNums || [];
  }

  getShipment() {
    return this.routeService.getShipmentById(this.getShipmentId())
  }

  getData() {
    return this.data;
  }
}