import { CommonModule } from "@angular/common";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TaskOverview } from "../base/task-overview";
import { LogHistoryComponent } from "../base/log-history";
import { ContactDriverComponent } from "../base/contact-driver-box";
import { Component } from "@angular/core";
import { BaseTaskComponent } from "../BaseTaskComponent";
import { TaskService } from "../services/task.service";
import { RouteService } from "../services/route.service";
import { ActionLogHistory } from "@app/interfaces";
import RouteEntity from "../entity/RouteEntity";
import StopEntity from "../entity/StopEntity";
import { Const } from "@const/Const";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { ETAConfirmationAnswerData } from "@app/interfaces/answer-data";
import { AnswersForTaskOTPConfirmation } from "./components/driver-answers";
import { ContactCarrierComponent } from "../base/contact-carrier-box";
import { DateUtil } from "@services/date-util";
import { UIHelper } from "@services/UIHelper";
import { Log } from "@services/log";


const CustomInputs = [
  CommonModule,
  NzGridModule,
  NzIconModule,
  NzStepsModule,

  TaskOverview,
  LogHistoryComponent,
  ContactDriverComponent,
  ContactCarrierComponent,
  AnswersForTaskOTPConfirmation,
]

@Component({
  selector: 'task-otp-confirmation',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskOTPConfirmation extends BaseTaskComponent {
  public stopTimeZone;
  private route: RouteEntity;
  public routeData: any;
  public currentStop: StopEntity;
  public contactEntity: 'driver' | 'carrier' = 'driver';
  public carrierId: string;
  public driverInfo: any;
  public logHistory: ActionLogHistory[] = [];
  public stopScheduleTime;
  public displayRouteInfos: any = null;

  constructor(
    private taskService: TaskService,
    private routeService: RouteService) {
    super();    
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  get taskName() {
    return Const.TrackingTaskLabel.otpConfirmation;
  }

  get shouldContactDriver() {
    return this.contactEntity === 'driver';
  }

  get questionTitle() {
    return `Call / Message Driver to ask "Are you the driver for route <b>${this.displayRouteInfos?.code}</b> from <b>${this.displayRouteInfos?.origin}</b> to <b>${this.displayRouteInfos?.destination}</b> with pickup at <b>${this.displayRouteInfos?.scheduledTime}</b>? Can you confirm you'll be on time for pickup?"`;
  }

  getRoute() {
    return this.route;
  }

  get needAutoCompleteTask() {
    // Nếu đã enroute thì auto complete task
    let route = this.routeService.getRoute();
    let routeStatus = route?.getStatus();
    if (routeStatus !== Const.JobStatus.created) return true;
    return false;
  }

  loadData() {
    if (this.needAutoCompleteTask) {
      this.onAutoCompleteTask();
      return;
    }
    this.route = this.routeService.getRoute();
    this.routeData = this.routeService.getRoute()?.getData();
    this.currentStop = this.taskService.getCurrentStopEntity();
    this.stopTimeZone = this.currentStop?.getTimeZoneStandard();
    this.stopScheduleTime = this.currentStop?.getScheduleTime();
    this.logHistory = this.taskService.getLogHistory();
    this.driverInfo = this.route?.getDriver();
    this.carrierId = this.route?.getCarrierId();
    this.displayRouteInfos = {
      code: this.route?.getCode(),
      origin: this.getFirstPickup(),
      destination: this.getLastDropoff(),
      scheduledTime: this.getStopScheduleTime()
    }
    this.getContactInfo();
  }

  getContactInfo() {
    this.contactEntity = 'driver';
    let listLogActions = this.logHistory.map(log => [...(log.actions || [])]).flat() || [];
    for (let action of listLogActions) {
      // Nếu gọi cho driver trước đó thì gọi cho carrier
      if (action === 'markContactDriverFailed') {
        this.contactEntity = 'carrier';
        break;
      }
      else if (action === 'markContactCarrierFailed') break;
    }
    if (!this.driverInfo) this.contactEntity = 'carrier';
  }

  onResponseChange(resp: ETAConfirmationAnswerData) {
    this.taskService.updateAnswerData(resp);
    this.onStepAnswerCompleted();
  }

  getStopScheduleTime() {
    let timeWindow = this.stopScheduleTime;
    return this.getDisplayTimeWindow(timeWindow);
  }

  private getDisplayTimeWindow(timeWindow) {
    if (!timeWindow) return 'N/A';
    let timezone = this.stopTimeZone;
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let windowStr = DateUtil.displayTimeWindow(timeWindow, {
      timezone: timezone,
      format: 'MMM DD, YYYY h:mm A',
      formatDateOnly: 'MMM DD, YYYY'
    })
    return `${windowStr} ${shortTz}`.trim();
  }

  async onAutoCompleteTask() {
    let route = this.routeService.getRoute();
    let routeStatus = route?.getStatus();
    let title;
    let content;
    if (routeStatus === Const.JobStatus.completed) {
      title = 'Route has been completed';
      content = 'This route has been completed.';
    }
    else if (routeStatus === Const.JobStatus.inProgress) {
      title = 'Route is in progress';
      content = 'This route is in progress.';
    }
    else if (routeStatus === Const.JobStatus.canceled) {
      title = 'Route has been canceled';
      content = 'This route has been canceled.'; 
    }
    UIHelper.confirm(content, () => {
      Log.d("Auto Complete Task");
      super.onAutoCompleteTask();
    }, {
      title: title,
      okText: 'Next Task',
      className: 'modal-complete-task',
      cancelDisabled: true
    })
  }

  getFirstPickup() {
    let stops = this.routeData?.stops || [];
    return this.getShortDisplayLocation(stops[0]);
  }

  getLastDropoff() {
    let stops = this.routeData?.stops || [];
    return this.getShortDisplayLocation(stops[stops.length - 1]);
  }

  getShortDisplayLocation(stop) {
    if (!stop) return '';
    let city = stop?.info?.addr?.city || '';
    let state = stop?.info?.addr?.state || '';
    return `${city}, ${state}`;
  }

}
