import { CompositeLayer,  TripsLayer } from 'deck.gl';
import _ from 'underscore'

export default class RouteLayer extends CompositeLayer {
    id: string
    static layerName = "RouteLayer"
    route: any
    selectRoute: (s: string) => void
    color: number[]
    options: any = {}

    constructor(id, route, options: any = {}) {
        super({id, data: route})
        this.id = id
        this.route = route
        this.color = options.color || [93, 93, 253]
        this.options = options
        this.selectRoute = options?.selectRoute
    }

    createRouteLayer() {
        if (!this.route) return null
        const { stops, start, startLocation, cost } = this.route || {}
        const startTime = this.route.startTime ?? (start?.start ?? (Date.now() / 1000))
        if (!stops?.length) return null
        let coordinates = stops.map(it => {
            const { location } = it || {}
            if (location?.latitude) {
                return [location.longitude, location.latitude]
            }
            const { lat, lng } = location?.latlng || {}
            return [lng, lat]
        })
        let times = stops.map(it => (it.arrTime ?? it.timeWindow?.start ?? startTime) - startTime)
        if (startLocation?.latlng?.lat && stops[0].type !== 'PICKUP') {
            const { latlng } = startLocation || {}
            coordinates = [[latlng.lng, latlng.lat]].concat(coordinates)
            times = [0].concat(times)
        }
        const totalTime = times[times.length - 1] - times[0]
        const info = cost ? `Shipments: ${stops.length / 2}\nMileage: ${(cost.travelDistance / 1609.34).toFixed(1)}mi \nHours: ${(totalTime / 3600).toFixed(1)}` : ''

        return new TripsLayer({
            id: this.id + '-trip',
            data: [{
                type: 'ROUTE',
                route: this.route,
                coordinates, times,
                info
            }],
            getPath: d => d.coordinates,
            getTimestamps: d => d.times,
            getColor: this.color,
            // opacity: 0.8,
            widthMinPixels: this.options.minWidth || 4,
            jointRounded: true,
            capRounded: true,
            fadeTrail: this.options.fading ?? true,
            trailLength: totalTime * 1.2,
            currentTime: totalTime,
            updateTriggers: {
                getPath: [this.route]
            },
            pickable: true,
            // onClick: (info, event) => {
            //     console.log(info)
            //     const { object } = info
            //     this.selectRoute && this.selectRoute(object.id)
            // }
        });
    }

    renderLayers() {
        return [this.createRouteLayer()];
    }
}