import { NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@services/input-helper";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { Contact } from "@wearewarp/types/data-model";
import { NzIconModule } from "ng-zorro-antd/icon";

const CustomInputs = [
  NgIf, NgFor,
  NzIconModule
]
@Component({
  selector: '[contact-carrier-box]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class ContactCarrierComponent {
  private _carrierId;
  @Input() set carrierId(value) {
    this._carrierId = value;
    this.getCarrier();
  }

  get carrierId() {
    return this._carrierId;
  }

  constructor(protected api: ApiService) {
  }

  public carrierInfo;
  public avtCharacter: string = '';
  public carrierName: string = '';
  public displayInfos: any = {};

  reset() {
    this.carrierInfo = null;
    this.carrierName = '';
    this.displayInfos = {};
  }

  getCarrier() {
    this.reset();
    if (!this.carrierId) return;
    let url = `${Const.APIURI_CARRIERS}/${this.carrierId}`;
    this.api.GET(url).subscribe({
      next: (res) => {
        this.carrierInfo = res.data;
        this.buildDisplayInfo();
      },
      error: (err) => {
        UIHelper.showErr(err);
      }
    })
  }

  buildDisplayInfo() {
    let basicInfo = this.carrierInfo?.basicInfo || {};
    this.carrierName = basicInfo?.name || '';
    this.displayInfos['contacts'] = [];
    for (let contact of basicInfo?.contacts || []) {
      let info = {
        fullName: this.getContactName(contact),
        phone: this.getContactPhone(contact),
        email: this.getContactEmail(contact)
      }
      this.displayInfos.contacts.push(info);
    }
  }

  get hasMcDot() {
    return this.carrierInfo?.basicInfo?.mc || this.carrierInfo?.basicInfo?.dot;
  }
  get mcDotTxt() {
    return `MC:${this.carrierInfo?.basicInfo?.mc || 'N/A'} / DOT: ${this.carrierInfo?.basicInfo?.dot || 'N/A'}`;
  }

  getContactName(contact: Contact) {
    if (contact?.fullName) return contact.fullName;
    return `${contact?.firstName || ''} ${contact?.lastName || ''}`.trim();
  }
  getContactPhone(contact: Contact) {
    return InputHelper.formatPhone(contact?.phone);
  }

  getContactEmail(contact: Contact) {
    return contact?.email || '';
  }

  copyToClipboard(value) {
    Utils.copyTextToClipboard(value, (e) => {
      if (e) {
        UIHelper.showErr("Cannot copy to clipboard");
      } else {
        UIHelper.showSuccess("Copy to clipboard successfully");
      }
    })
  }
}