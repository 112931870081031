import { Env, EnvType } from "./type";

export const environment: Env = {
  type: EnvType.dev,
  socketUrl: 'https://dev.wearewarp.com',
  backendUrl: 'https://dev.wearewarp.com',
  authUrl: 'https://auth.dev.wearewarp.com',
  backendWorkQueueUrl: 'https://works.dev.wearewarp.com',
  dataorchUrl: '',
  eventUrl: 'https://dev-event.wearewarp.link',
  mapboxgl: {
    useProxy: true,
    apiUrl: 'https://mapbox.dev.wearewarp.com',
    eventUrl: 'https://mapbox.dev.wearewarp.com/events/v2',
  }
};
