import { Stop } from "@wearewarp/types/data-model";
import RouteTaskEntity from "./RouteTaskEntity";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-util";
import { UsTimezone } from "@wearewarp/types";
import { RouteService } from "../services/route.service";
import { TimeWindow } from "@app/interfaces";


export default class StopEntity {
  private data: Stop;
  private routeService: RouteService;
  private index: number;

  public setRouteService(routeService) {
    if (routeService) {
      this.routeService = routeService;
    }
    return this;
  }

  init (data) {
    this.data = data;
    return this;
  }

  getData() {
    return this.data;
  }

  setIndex(index) {
    this.index = index;
    return this;
  }

  getIndex() {
    return this.index
  }

  getDisplayIndex() {
    return this.index + 1;
  }

  getId() {
    return this.data.id;
  }

  getRouteTaskIds() {
    return this.data.taskIds;
  }

  getRouteTasks() {
    return this.data.taskIds.map(taskId => this.routeService.getRouteTaskById(taskId))
  }

  getFirstRouteTask(): RouteTaskEntity | undefined {
    return this.getRouteTasks()?.find(task => ![Const.RouteTaskStatus.canceled, Const.RouteTaskStatus.pickupFailed].includes(<any>task.getStatus()));
  }

  getArrivedTime() {
    return this.getFirstRouteTask()?.getArrivedTime()
  }
  getDepartedTime() {
    return this.getFirstRouteTask()?.getDepartedTime()
  }
  getETA() {
    return this.getFirstRouteTask()?.getETA()
  }

  getAppointment() {
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) return appointment;
    return;
  }

  getScheduleTime(): TimeWindow {
    let appointment = this.data?.info?.appointmentInfo;
    let window = this.data?.info?.windows?.[0];
    // Nếu có appointment hoặc window thì lấy luôn
    if (appointment?.from || appointment?.to) {
      return appointment;
    }
    if (Utils.isObjectNotEmpty(window)) {
      return window;
    }

  }

  getTimeZone() {
    return this.data?.info?.addr?.metadata?.timeZone;
  }

  getTimeZoneStandard() {
    let timezone = this.getTimeZone();
    let timeZoneStandard = '';
    let listUsTimezones = <any[]>DateUtil.listUsTimezones;

    if (listUsTimezones.includes(timezone)) {
      timeZoneStandard = DateUtil.mapTimezoneUS(timezone as UsTimezone);
    }
    return this.data?.info?.addr?.metadata?.timeZoneStandard || timeZoneStandard;
  }

  getLocationName() {
    return this.data.info.locationName || ""
  }
  getAddressText() {
    return Utils.getAddressStr(this.data.info.addr)
  }
  getAddress() {
    return this.data.info.addr
  }

  getRepresentativeTask() {
    return this.getRouteTasks().filter(it => it.getStatus() === 'enroute' || it.getStatus() === 'created')?.[0]?.getData() || undefined;
  }

  getType() {
    return this.data.type;
  }

  getStatus() {
    return this.data.status
  }

  getRefNums() {
    let refNums = [];
    let tasks = this.getRouteTasks() || [];
    for (let task of tasks) {
      refNums = [ ...refNums, ...task.getRefNums() ];
    }
    return Utils.uniqElementsArray(refNums);
  }

  getDeliveryInfo() {
    return this.data.info
  }

  getShipments() {
    return this.getRouteTasks().map(task => task.getShipment()).filter(it => it);
  }

  getShipmentDeliveryInfo() {
    if (this.getType() == Const.RouteTaskType.PICKUP) {
      return this.getShipments().map(item => item.getPickInfo());
    } else if (this.getType() == Const.RouteTaskType.DROPOFF) {
      return this.getShipments().map(item => item.getDropInfo());
    }
    return []
  }

  getServiceOptions() {
    return this.data?.info?.serviceOptions || [];
  }

}