import { Component, Input } from "@angular/core";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { BaseComponent } from "@abstract/BaseComponent";
import { CommonModule, NgFor, NgIf } from "@angular/common";
import { DateUtil } from "@services/date-util";
import { UIHelper } from "@services/UIHelper";
import { Const } from "@const/Const";
import { TimeWindow } from "@app/interfaces";
import { Utils } from "@services/utils";
import { AttachedFileUtil } from "@services/attached-file-util";
import { DialogService } from "@app/dialogs/dialog.service";

interface NoteItem {
  content: string,
  imgArr?: any[],
  longAgo: any,
  collection: string,
  collectionName: string
  avtCharacter: string
}

const CustomInputs = [
  CommonModule,
  NgIf, NgFor,
  NzIconModule,
  NzPopoverModule,
  FormsModule,
  NzInputModule,
  NzButtonModule,
  NzGridModule,
  NzBadgeModule,
  NzEmptyModule
]

@Component({
  selector: '[note-box]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class NoteBoxComponent extends BaseComponent {
  private _jobId: string;

  @Input() get jobId() {
    return this._jobId;
  }
  set jobId(value) {
    this._jobId = value;
  }

  private timerReloadData;
  private onProgress: boolean = false;
  public noteItems: any[] = [];
  public currentTime: string;    // timeiso utc
  public noteBoxVisiable: boolean = false;
  public countOldItems: number = 0;   // tổng số message cũ
  public countNewItems: number = 0;   // số message mới

  ngOnChanges(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
    }
    this.reloadData();
    this.timerReloadData = setInterval(() => {
      this.reloadData();
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
      this.timerReloadData = null;
    }
    super.ngOnDestroy();
  }

  async reloadData() {
    this.currentTime = DateUtil.convertLocalTime(new Date(), DateUtil.getLocalTimeZone()).toISOString();
    this.countOldItems = this.noteItems.length || 0;
    this.fetchData();
  }

  onBtnCloseNoteBox() {
    this.noteBoxVisiable = false;
  }

  // sắp xếp lại tin nhắn theo thời gian sớm nhất -> muộn nhất
  sortData(data) {
    return data.sort((a, b) => {
      let timeA = new Date(a.insert?.when).getTime();
      let timeB = new Date(b.insert?.when).getTime();
      return timeB - timeA;
    })
  }

  buildDisplayNotes(data) {
    data = this.sortData(data);
    let items: any[] = [];
    for (let item of data) {
      let collectionName = this.getCollectionName(item);
      let imgArr: any[] = [];
      if (Utils.isArrayNotEmpty(item?.imageUploadFilesArr)) {
        for (let img of item.imageUploadFilesArr) {
          let imgUrl = AttachedFileUtil.attachedFileUrl(img);
          if (imgUrl) imgArr.push(imgUrl);
        }
      }
      let msgItem: NoteItem = {
        longAgo: this.getTimeLongAgo(item.insert?.when),
        content: item.content || 'N/A',
        imgArr: imgArr,
        collection: item.insert?.collection,
        collectionName: collectionName,
        avtCharacter: Utils.getAvatarCharacter(collectionName)
      }
      items.push(msgItem);
    }
    return items;
  }

  getCollectionName(item) {
    let data;
    let collection = item?.insert?.collection;
    if (collection === 'users') data = item?.user;
    if (collection === 'drivers') data = item?.driver;

    if (!data) return 'N/A';
    if (data?.fullName) return data.fullName;
    return `${data?.firstName || ''} ${data?.lastName || ''}`.trim();
  }

  getTimeLongAgo(time: string) {
    if (!time) return '';
    // time và current time đều được convert về iso utc
    let window: TimeWindow = {
      from: time,
      to: this.currentTime
    }
    let duration = DateUtil.getTimeDurationFromWindow(window);
    duration = Math.round(duration / (1000 * 60));   // convert to mins
    return DateUtil.getParseDurationTimeAgo(duration);
  }

  async fetchData() {
    if (!this.jobId) return UIHelper.showErr(`Invalid jobId ${this.jobId}`)
    this.onProgress = true;
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=note`).subscribe({
      next: (resp) => {
        const listData = resp?.data?.list_data || [];
        this.noteItems = this.buildDisplayNotes(listData);
        this.countNewItems = this.noteItems.length - this.countOldItems || 0;
        this.onProgress = false;
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.onProgress = false;
      }
    });
  }

  onVisiableChange() {
    this.countNewItems = 0;
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImg(imgUrl);
  }
}