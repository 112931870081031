import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Output } from "@angular/core";
import { TaskService } from "../../services/task.service";
import { ActionLogHistory, LogAction } from "@app/interfaces";
import { DateUtil } from "@services/date-util";
import { NzTooltipTrigger } from "ng-zorro-antd/tooltip";

@Directive()
export class BaseTaskAnswer implements AfterViewInit{
  constructor(protected taskService: TaskService, protected cd: ChangeDetectorRef) {
  }

  @Output() responseChange: EventEmitter<any> = new EventEmitter<any>();

  optionArr: any = [];
  answerType: any;
  answerData: any = {};
  templateController: any = {};
  formAnswers: any = {};

  ngAfterViewInit() {
    this.cd.detectChanges();      // fix bug expression changed for getTemplate()
  }

  getValue(key) {
    return this.formAnswers[key]?.value || '';
  }

  getLabel(key) {
    return this.formAnswers[key]?.label || '';
  }
  // can be override
  getDesc(key) {
    return this.formAnswers[key]?.desc || '';
  }

  getTooltipTrigger(key): NzTooltipTrigger {
    return this.templateController[key].trigger;
  }

  get spanOfItem() {
    let numOfItems = this.optionArr.length || 3;
    return 24 / numOfItems;
  }

  shouldEnableRadio(key) {
    return this.getValue(key) === this.answerType;
  }

  onResponseChange(data) {
    this.updateLogHistory(data);
    this.responseChange.emit(data);
  }

  updateLogHistory(answerData) {
    // Log có thể chỉ cần actions, có thể không có type, data
    const { type, data, actions } = answerData;
    let newLog: ActionLogHistory = {
      type,
      data,
      actions: type ? this.getLogActions(type) : actions
    };
    this.taskService.updateLogHistory(newLog);
  }

  // Override this method for each task
  getLogActions(type): LogAction[] {
    return [];
  }

  // Khi answer thay đổi thì reset lại, không để trả ra sai thông tin
  resetAnswerData() {
    this.answerData = {};
  }

  getCurrentStopTimeZone() {
    let currentStop = this.taskService.getCurrentStopEntity();
    return currentStop?.getTimeZoneStandard() || '';
  }

  getDisplayLocalTime(timeIso: string, timezone?: string) {
    if (!timezone) timezone = this.getCurrentStopTimeZone();
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime = DateUtil.displayLocalTime(timeIso, timezone, 'MMM DD, YYYY h:mm A');
    return `${localTime} ${shortTz}`.trim();
  }
}