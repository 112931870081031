import { BaseComponent } from "@abstract/BaseComponent";
import { mixinHTMLElementWidthChangedNotifier } from "@abstract/HTMLElementWidthChangedNotifier";
import { NgClass } from "@angular/common";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NavigationEnd, RouterModule } from "@angular/router";

const CustomInputs = [
  RouterModule,
  FormsModule,
  NgClass
]
@Component({
  selector: '[public]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class PublicComponent extends mixinHTMLElementWidthChangedNotifier(BaseComponent) {

  @ViewChild('content', {static: false}) content: ElementRef<HTMLDivElement>;

  protected get contentElement(): HTMLElement { return this.content.nativeElement }

  constructor() {
    super();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.topNav?.onNavigationEnd(event.url);
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.handleInitRoute();
  }

  private handleInitRoute() {
    const url = this.router.url.split('?')[0];
    if (url === '/verify') {
      return;
    }
    for (let route of this.router.config) {
      if (route.component?.name == PublicComponent.name) {
        for (let child of route?.children || []) {
          if (url.indexOf(`/${child.path}`) == 0) {
            // Với những sub-route bên trong PublicComponent mà được khai báo tường minh thì sẽ xử lý tiếp ở component
            return;
          }
        }
      }
    }
    if (this.authUser) {
      this.goDashboard();
    } else {
      this.goLogin();
    }
  }

  protected notifyContentWidthChanged(value: number) {
    super.notifyContentWidthChanged(value);
  }

  onRouterActivate(compRef) {
    compRef.parentComponent = this;
  }

}