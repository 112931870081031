<div class="answers-container">
  <nz-radio-group [(ngModel)]="answerType">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24 / optionArr.length" *ngFor="let key of optionArr">
        <div class="box-status-item"
          (click)="onTooltipClick(key)"
          style="cursor: pointer;"
        >
          <div class="flex flex-space-between">
            <div class="label-text">{{ getLabel(key)}}</div>
            <div><label nz-radio [nzValue]="getValue(key)" [nzDisabled]="!shouldEnableRadio(key)"></label></div>
          </div>
          <div class="text-desc top20" style="white-space: pre-line;" [innerHTML]="getDesc(key)"></div>
        </div>
      </div>
    </div>
  </nz-radio-group>
</div>