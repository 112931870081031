import { ApiService } from "@services/api.service";

export interface CompleteTaskServiceInterface {
  completeTask(data: any): Promise<any>;
}

export class BaseCompleteTaskService implements CompleteTaskServiceInterface {
  constructor(protected api: ApiService) {

  }
  
  public async completeTask(data: any) {
    throw new Error('Method not implemented.');
  }

}