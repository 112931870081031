import to from "await-to-js";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseFormItem } from "@app/base-components/form-item";
import { InputHelper } from "@services/input-helper";
import { Const } from "@const/Const";
import { lastValueFrom } from "rxjs";
import { verifyAfterLogin, verifyPasswordExpired } from "@services/auth.check-role";
import { UIHelper } from "@services/UIHelper";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { CommonModule } from "@angular/common";
import { Utils } from "@services/utils";

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzInputModule,
  NzIconModule,
  NzButtonModule
]

@Component({
  selector: '[login]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../public/style.scss']
})

export class Login extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    email: { label: 'Email:', required: true, placeHolder: 'Email' },
    pw: { label: 'Password:', required: true, placeHolder: 'Password' }
  };
  public passwordVisible = false;
  public onProgress = false;
  
  get canLogin() { return !this.onProgress && this.needUpdate }
  get isLoginGgInProgress(): boolean { return this.loginGgAuthUrlFetching || this.loginGgAuthCodeVerifying }
  private loginGgAuthUrlFetching = false;
  private loginGgAuthCodeVerifying = false;

  @ViewChild('inputEmail') inputEmail: ElementRef<HTMLInputElement>;
  @ViewChild('inputPw') inputPw: ElementRef<HTMLInputElement>;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isLoggedIn) {
      return this.goDashboard();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        return this.handleLoginGoogle(params.code);
      }
    });
  }

  // Lấy user profile để kiểm tra permission
  // Nếu không có quyền vào dashboard thì warning
  private async loginSuccess() {
    await this.appComponent.loginSucceeded();
    if (!verifyPasswordExpired(this.authUser, this.router)) return;
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    let preUrl = params?.preUrl || '';
    verifyAfterLogin(this.authUser, this.router, preUrl);
  }

  onEmailKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    this.inputPw?.nativeElement.focus();
  }

  onPwKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (this.canLogin) {
      this.onBtnLogin();
    }
  }

  async onBtnLogin() {
    const email = this.getItemValue('email');
    const pw = this.getItemValue('pw');
    this.setProgress(true);
    let [err, result] = await to(lastValueFrom(this.auth.login(email, pw)));
    if (err) {
      UIHelper.showErr(err);
    } else if (result?.needOtp) {
      this.router.navigate([Const.routeVerifyLogin], { queryParams: { loginData: result?.loginData } });
    } else {
      await this.loginSuccess();
    }
    this.setProgress(false);
  }

  private setProgress(value: boolean) {
    this.onProgress = value;
  }

  async onBtnLoginGoogle() {
    this.loginGgAuthUrlFetching = true;
    let [err, result] = await to(lastValueFrom(this.auth.googleAuthUrl()));
    if (err) {
      UIHelper.showErr(err);
      this.loginGgAuthUrlFetching = false;
    } else {
      const authUrl = result.data.authUrl;
      window.location.href = authUrl;
    }
  }


  private async handleLoginGoogle(code: string) {
    this.loginGgAuthCodeVerifying = true;
    const [err, result] = await to(lastValueFrom(this.auth.googleAuthVerify(code)));
    if (err) {
      UIHelper.showErr(err);
      this.router.navigate([Const.routeLogin]);
      this.loginGgAuthCodeVerifying = false;
    } else if (result?.needOtp) {
      this.router.navigate([Const.routeVerifyLogin], { queryParams: { ggToken: result?.ggToken } });
    } else {
      await this.loginSuccess();
    }
  }

}