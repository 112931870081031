import { Injector } from '@angular/core';
import { DispatcherDashboardComponent } from '@app/work-dispatcher/dashboard';
import { NavigationEndData, NavigationUrlData, WorkOperatorAuthUser } from '@app/interfaces';
import { Subscription } from 'rxjs';

export interface App {
  loginSucceeded(): Promise<void>;
  subscribeWindowResize(observerOrNext?: any, error?: any, complete?: any): Subscription;
  subscribeNavigationEnd(observerOrNext?: any, error?: any, complete?: any): Subscription;
  getCurrentNavigationUrlData(): NavigationUrlData;
  createNavigationEndData(): NavigationEndData;
  getAuthUser(): WorkOperatorAuthUser;
  myProfileChanged(): Promise<void>;
  scrollToTop(): void;
  isFirstLoadingOK(): boolean;
}

let appInjector: Injector;
let appComponent: App;
let dispatcherDashboard: DispatcherDashboardComponent;

export function setInjector(injector: Injector) {
  appInjector = injector;
}

export function getInjector(): Injector {
  return appInjector;
}

export function setApp(app: App) {
  appComponent = app;
}

export function getApp(): App {
  return appComponent;
}

export function setDispatcherDashboard(comp: DispatcherDashboardComponent) {
  dispatcherDashboard = comp;
}
