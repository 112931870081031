export enum EnvType {
  dev = 'development',
  stg = 'staging',
  demo = 'demo',
  prod = 'production'
}

export interface Env {
  type: EnvType,
  socketUrl: string,
  backendUrl: string,
  authUrl: string,
  backendWorkQueueUrl: string,
  dataorchUrl: string,
  eventUrl: string,
  mapboxgl: {
    useProxy: boolean,
    apiUrl: string,
    eventUrl: string
  }
}