import { NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NzButtonModule } from "ng-zorro-antd/button";


const CustomInputs = [
  NgClass, NgIf,
  NzButtonModule
]
@Component({
  selector: '[live-tracking-status]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class LiveTrackingStatus {
  @Input() isEnableLiveTracking: boolean = false;
  // @Output() changeMapVisible: EventEmitter<any> = new EventEmitter<any>();
  // isVisible: boolean = false;
  // ngOnChanges() {
  //   this.isVisible = this.isEnableLiveTracking;
  // }

  get liveTrackingDesc() {
    let title = "Driver's tracking location is turned OFF";
    if (this.isEnableLiveTracking) {
      title = "Live-tracking is ON. See driver's detailed location";
    }
    return title;
  }

  // onChangeMapVisible() {
  //   this.changeMapVisible.emit();
  // }
}