import { NgFor, NgIf } from "@angular/common";
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LogAction } from "@app/interfaces";
import { ETAConfirmationAnswerData } from "@app/interfaces/answer-data";
import { BaseTaskAnswer } from "@app/work-dispatcher/task-queue/base/base-task-answer";
import { DateUtil } from "@services/date-util";
import { UIHelper } from "@services/UIHelper";
import { AnswersTaskETAConfirmation } from "@wearewarp/types/data-model/types/work-queue";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

const CustomInputs = [
  NgIf, NgFor,
  FormsModule,
  NzInputModule,
  NzRadioModule,
  NzGridModule,
  NzButtonModule,
  NzToolTipModule,
  NzDatePickerModule
]

@Component({
  selector: '[answers-for-task-eta-confirmation]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class AnswersForTaskETAConfirmation  extends BaseTaskAnswer {
  @Input() contactEntity: 'driver' | 'carrier' = 'driver';  // thông tin xem liên hệ với ai -> log action

  @ViewChild('tplMarkDriverOnTime') tplMarkDriverOnTime!: TemplateRef<any>;
  @ViewChild('tplMarkDriverLate') tplMarkDriverLate!: TemplateRef<any>;
  @ViewChild('tplMarkDriverNotPickup') tplMarkDriverNotPickup!: TemplateRef<any>;
  @ViewChild('tplMarkContactFailed') tplMarkContactFailed!: TemplateRef<any>;

  optionArr: AnswersTaskETAConfirmation[] = [];
  answerType: AnswersTaskETAConfirmation | undefined = undefined;
  answerData: ETAConfirmationAnswerData = {}
  formAnswers = {
    driverOnTime: {
      label: 'Driver will be on-time',
      value: 'driverOnTime',
      desc: '' },
    driverLate: {
      label: 'Driver might be late',
      value: 'driverLate',
      desc: '(Late means time has past Schedule (End time))\nAsk\n<ol><li>"May I know the reason for the delay?"</li><li>"Can you give me ETA?"</li></ol>Remind Driver "Please arrive to PU ASAP"' },
    driverNotPickup: {
      label: `Driver can't pickup`,
      value: 'driverNotPickup',
      desc: `Ask "What happen?"` },
    contactFailed: {
      label: 'Contact failed',
      value: 'contactFailed',
      desc: `Enter note` },
  }
  templateController = {
    driverOnTime: { visible: false, trigger: 'click' },
    driverLate: { visible: false, trigger: 'click' },
    driverNotPickup: { visible: false, trigger: 'click' },
    contactFailed: { visible: false, trigger: 'click' },
  }
  tmpNoteContactFailed: string = '';
  tmpReason: string = '';
  tmpETA;
  scheduleTimeWindow; 

  ngOnInit() {
    this.scheduleTimeWindow = this.taskService?.getCurrentStopEntity()?.getScheduleTime();
    this.initAnswerOptions();
  }

  initAnswerOptions() {
    this.optionArr = ['driverOnTime', 'driverLate', 'driverNotPickup', 'contactFailed'];
  }

  getDesc(key) {
    if (key === this.answerType) {
      if (key === 'driverOnTime' && this.answerData?.data?.eta) {
        let etaLocalTime = this.getDisplayLocalTime(this.answerData.data.eta);
        return `ETA: ${etaLocalTime}`;
      }
      if (key === 'driverLate' && this.answerData?.data?.eta) {
        let reason = this.answerData.data.reason || '';
        let etaLocalTime = this.getDisplayLocalTime(this.answerData.data.eta);
        return `ETA: ${etaLocalTime}\nReason: ${reason}`;
      }
      if (key === 'driverNotPickup' && this.answerData?.data?.reason) {
        return `Reason: ${this.answerData.data.reason}`;
      }
      if (key === 'contactFailed') {
        if (this.answerData?.data?.needRecovery) {
          return `Recovery request will be triggered`;
        }
        else if (this.answerData?.data?.note) {
          return `Note: ${this.answerData.data.note}`;
        }
      }  
    }
    return this.formAnswers[key]?.desc || '';
  }

  getTemplate(status): TemplateRef<any> {
    switch (status) {
      case 'driverOnTime': return this.tplMarkDriverOnTime;
      case 'driverLate': return this.tplMarkDriverLate;
      case 'driverNotPickup': return this.tplMarkDriverNotPickup;
      case 'contactFailed': return this.tplMarkContactFailed;
    }
  }

  getLogActions(type: AnswersTaskETAConfirmation): LogAction[] {
    switch (type) {
      case 'driverOnTime': return ['updateETA'];
      case 'driverLate': return ['updateETA', 'noteReason'];
      case 'driverNotPickup': return ['noteReason'];
      case 'contactFailed': 
        if (this.contactEntity === 'carrier')
          return ['markContactCarrierFailed'];
        return ['markContactDriverFailed'];
      default: return [];
    }
  }

  updateAnswer(key: AnswersTaskETAConfirmation) {
    try {
      this.resetAnswerData();
      this.answerType = key;
      this.answerData.type = key;
      if (key === 'driverOnTime') this.onMarkDriverOnTime();
      else if (key === 'driverLate') this.onMarkDriverLate();
      else if (key === 'driverNotPickup') this.onMarkDriverNotPickup();
      else if (key === 'contactFailed') this.onMarkContactFailed();
      this.onResponseChange(this.answerData);
      UIHelper.showSuccess('update successfully');
    }
    catch (e) {
      this.answerType = null;
      UIHelper.showErr(e);
    }
  }

  onMarkDriverOnTime() {
    this.answerData.data = { 
      eta: DateUtil.convertLocalTime(this.tmpETA, this.getCurrentStopTimeZone()) 
    };
    this.tmpETA = null;
  }
  onMarkDriverLate() {
    this.answerData.data = { 
      eta: DateUtil.convertLocalTime(this.tmpETA, this.getCurrentStopTimeZone()),
      reason: this.tmpReason
    };
    this.tmpETA = null;
    this.tmpReason = '';
  }
  onMarkDriverNotPickup() {
    this.answerData.data = { reason: this.tmpReason };
    this.tmpReason = '';
  }
  onMarkContactFailed() {
    this.answerData.data = { note: this.tmpNoteContactFailed };
    const histories = this.taskService?.getLogHistory();
    const actions = histories?.map(h => [...(h.actions || [])])?.flat() || [];
    let numCallFailed = actions.filter(a => a === 'markContactDriverFailed' || a === 'markContactCarrierFailed').length;
    // Đến lần thứ 3 thì complete task
    if (numCallFailed >= 2) {
      this.answerData.data['shouldCompleteTask'] = true;
      this.answerData.data['needRecovery'] = true;
    }
    this.tmpNoteContactFailed = '';
  }

  canUpdateAnswer(key: AnswersTaskETAConfirmation) {
    switch (key) {
      case 'driverOnTime': return this.tmpETA && !this.etaOnTimeErr ? true : false;
      case 'driverLate': return this.tmpETA && this.tmpReason && !this.etaLateErr ? true : false;
      case 'driverNotPickup': return this.tmpReason ? true : false;
      case 'contactFailed': return this.tmpNoteContactFailed ? true : false;
    }
  }

  etaOnTimeErr = '';
  etaLateErr = '';
  validateTimeInput(key: AnswersTaskETAConfirmation) {
    let localETATime = this.tmpETA ? DateUtil.convertLocalTime(this.tmpETA, this.getCurrentStopTimeZone()).toISOString() : '';
    let duration = DateUtil.getTimeDurationFromWindow({
      from: localETATime,
      to: this.scheduleTimeWindow?.to || this.scheduleTimeWindow?.from
    })
    if (key === 'driverOnTime') {
      this.etaOnTimeErr = '';
      if (localETATime && duration < 0) {
        this.etaOnTimeErr = `ETA exceeds the scheduled end time. The driver should be late. Please double-check.`
      }
    }
    else if (key === 'driverLate') {
      this.etaLateErr = '';
      if (localETATime && duration > 0) {
        this.etaLateErr = `ETA hasn't exceeded scheduled end time. Driver should be on-time. Please double-check.`
      }
    }
  }

}