import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { AnswersTaskRouteConfirmation } from "@wearewarp/types/data-model/types/work-queue"
import { NzStepsModule } from "ng-zorro-antd/steps";
import { RouteCheckListComponent } from "../route-check-list";
import RouteEntity from "@app/work-dispatcher/task-queue/entity/RouteEntity";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { RouteConfirmationAnswerData } from "@app/interfaces/answer-data";
import { UIHelper } from "@services/UIHelper";
import { BaseTaskAnswer } from "@app/work-dispatcher/task-queue/base/base-task-answer";
import { LogAction } from "@app/interfaces";
import { DateUtil } from "@services/date-util";

const CustomInputs = [
  NgIf, NgFor,
  FormsModule,
  NzInputModule,
  NzRadioModule,
  NzGridModule,
  NzButtonModule,
  NzToolTipModule,
  NzStepsModule,
  NzIconModule,
  NzSelectModule,
  RouteCheckListComponent,
]

@Component({
  selector: '[carrier-answers-for-task-route-confirmation]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class CarrierAnswerForTaskRouteConfirmation extends BaseTaskAnswer {
  private _routeEntity: RouteEntity;
  @Input() set routeEntity(value: RouteEntity) {
    this._routeEntity = value;
    if (this.checkNeedRecovery()) {
      this.needRecovery = true;
    }
  }
  get routeEntity() {
    return this._routeEntity;
  }
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tplMarkKeepThisRoute') tplMarkKeepThisRoute: TemplateRef<any>;
  @ViewChild('tplMarkWaitingForConfirmation') tplMarkWaitingForConfirmation: TemplateRef<any>;
  @ViewChild('tplMarkDeclinedRoute') tplMarkDeclinedRoute: TemplateRef<any>;

  optionArr: AnswersTaskRouteConfirmation[]  = [];
  carrierStatus: AnswersTaskRouteConfirmation | undefined = undefined;
  formCarrierAnswer = {
    keepRoute: { 
      label: 'Carrier wants to keep this route', 
      value: 'keepRoute', 
      desc: 'Please confirm here.' },
    waitingForConfirmation: { 
      label: `Carrier wants to keep this route but can't confirm via email during the call`, 
      value: 'waitingForConfirmation', 
      desc: 'Make sure carrier is aware that the route is very soon. And enter follow-up time.' },
    declinedRoute: { 
      label: 'Carrier declined this route', 
      value: 'declinedRoute', 
      desc: 'Enter reason' },
    contactFailed: { 
      label: 'Contact failed', 
      value: 'contactFailed', 
      desc: 'Follow-up after 30 mins' },
    contactedViaSms: {
      label: 'Contacted via Sms/Email',
      value: 'contactedViaSms',
      desc: `Follow-up after 10 mins` },
  }
  templateController = {
    keepRoute: { visible: false, trigger: null },
    waitingForConfirmation: { visible: false, trigger: 'click' },
    declinedRoute: { visible: false, trigger: 'click' },
    contactFailed: { visible: false, trigger: 'click' },
    contactedViaSms: { visible: false, trigger: 'click' },
  }
  delayConfirmOptions = [15, 30, 45, 60, 90, 120];  // mins
  answerData: RouteConfirmationAnswerData = {};
  needRecovery: boolean = false;
  tmpDeclinedReason: string = '';
  tmpDelayTime: number = 30;

  tmpOtherReason: string = '';
  get declinedReasonOptions() {
    return [
      `Carrier doesn’t have this equipment`,
      `Pickup location is too far `,
      `Driver is not available at the pickup date`,
      `The vehicle is not available at the pickup date`,
      `Carrier didn't receive the booking confirmation email `,
    ]
  }

  otherDeclinedReasonInputChange(event) {
    this.tmpDeclinedReason = this.tmpOtherReason;
  }

  ngOnInit() {
    this.initAnswerOptions();
  }

  ngOnChanges() {
    this.initAnswerOptions();
  }

  initAnswerOptions() {
    if (this.isConfirmedLoadtender) {
      this.optionArr = ['keepRoute', 'contactedViaSms', 'declinedRoute', 'contactFailed'];
    }
    else if (!this.requireCarrierAcceptLoadtender) {
      this.optionArr = ['keepRoute', 'declinedRoute', 'contactFailed'];
    } else {
      this.optionArr = ['keepRoute', 'waitingForConfirmation', 'declinedRoute', 'contactFailed'];
    }
  }

  get isConfirmedLoadtender() {
    return this.routeEntity?.getConfirmLoadtenderStatus() === 'accepted';
  }

  get requireCarrierAcceptLoadtender() {
    return this.routeEntity.getData()?.settings?.isRequireCarrierAcceptLoadTender;
  }

  get spanOfItem() {
    let numOfItems = this.optionArr.length || 3;
    return 24 / numOfItems;
  }

  getValue(key) {
    return this.formCarrierAnswer[key]?.value || '';
  }

  getLabel(key) {
    if (key === 'keepRoute' && !this.isConfirmedLoadtender) {
      return 'Carrier wants to keep this route and can confirm via email during the call';
    }
    return this.formCarrierAnswer[key]?.label || '';
  }

  getDesc(key) {
    if (key === 'declinedRoute' && this.answerData?.data?.reason) {
      return `Reason: ${this.answerData.data.reason}`;
    }
    if (key === 'waitingForConfirmation' && this.answerData?.data?.delayTime) {
      return `Make sure carrier is aware that the route is very soon. They must confirm within ${this.answerData.data.delayTime} mins. `
    }
    if (key === 'contactFailed') {
      if (this.needRecovery) {
        return `Recovery request will be triggered`;
      }
      return `Follow-up after 30 mins`;
    }
    return this.formCarrierAnswer[key]?.desc || '';
  }

  needSpecialDesc(key) {
    if (key === 'keepRoute' && !this.isConfirmedLoadtender) {
      return true;
    }
    return false;
  }

  shouldEnableRadio(key) {
    return this.getValue(key) === this.carrierStatus;
  }

  getTemplate(key): TemplateRef<any> {
    switch (key) {
      case 'keepRoute': return this.tplMarkKeepThisRoute;
      case 'waitingForConfirmation': return this.tplMarkWaitingForConfirmation;
      case 'declinedRoute': return this.tplMarkDeclinedRoute;
      default: return;
    }
  }

  onTooltipClick(key: AnswersTaskRouteConfirmation) {
    if (key === 'contactFailed' || key === 'contactedViaSms') {
      this.updateCarrierAnswer(key);
      return;
    }
    for (let item of Object.keys(this.templateController)) {
      if (item !== key) {
        this.templateController[item].visible = false;
      }
    }
    if (!this.templateController[key] || this.templateController[key]?.trigger === 'click') return;
    this.templateController[key].visible = !this.templateController[key].visible;
  }

  onCloseTooltip(key) {
    this.templateController[key].visible = false;
  }

  canUpdate(key: AnswersTaskRouteConfirmation) {
    switch (key) {
      case 'declinedRoute': return this.tmpDeclinedReason;
      case 'waitingForConfirmation': return this.tmpDelayTime;
    }
  }

  getLogActions(type: AnswersTaskRouteConfirmation): LogAction[] {
    switch (type) {
      case 'keepRoute': return ['markCarrierKeepRoute'];
      case 'waitingForConfirmation': return ['markCarrierWaitingForConfirmation'];
      case 'declinedRoute': return ['markCarrierDeclinedRoute'];
      case 'contactFailed': return ['markContactCarrierFailed'];
      case 'contactedViaSms': return ['markContactedViaSms'];
      default: return [];
    }
  }

  carrierKeepThisRoute(value) {
    this.updateCarrierAnswer('keepRoute');
  }
  
  updateCarrierAnswer(key: AnswersTaskRouteConfirmation) {
    try {
      this.onCloseTooltip(key);
      this.resetAnswerData();
      this.carrierStatus = key;
      this.answerData.type = key;
      if (key === 'waitingForConfirmation') this.onMarkWatingForConfirmation();
      else if (key === 'declinedRoute') this.onMarkCarrierDeclinedRoute();
      else if (key === 'contactFailed') this.onMarkContactFailed();
      else if (key === 'contactedViaSms') this.onMarkContactedViaSms();
      this.onResponseChange(this.answerData);
    }
    catch (e) {
      this.carrierStatus = null;
      UIHelper.showErr(e);
    }
  }

  onMarkWatingForConfirmation() {
    this.answerData.data = { delayTime: this.tmpDelayTime };
    if (this.needRecovery) {
      this.answerData.data.needRecovery = true;
    }
    this.tmpDelayTime = null;
  }

  onMarkCarrierDeclinedRoute() {
    this.answerData.data = { reason: this.tmpDeclinedReason };
    this.tmpDeclinedReason = '';
    this.tmpOtherReason = '';
  }

  onMarkContactFailed() {
    if (this.needRecovery) {
      this.answerData.data = { needRecovery: true }
    }
  }

  onMarkContactedViaSms() {
    this.answerData.data = {};
  }

  checkNeedRecovery(): boolean {
    let localCurrentTime = DateUtil.getLocalCurrentTimeISO();
    let pickupTime = this.routeEntity?.getData()?.pickDt?.time;
    if (!pickupTime) return false;
    let duration = DateUtil.getTimeDurationFromWindow({ 
      from: localCurrentTime, 
      to: pickupTime 
    });
    // Nếu còn hơn 12h thì không cần recovery
    if (duration > (1000 * 60 * 60 * 12)) return false;
    return true;
  }

  getGuideline(stepIndex) {
    switch (stepIndex) {
      case 1:
        return `Find the confirmation email by searching for "${this.routeEntity?.getCode()} Booking Confirmation" in the inbox`;
      case 2:
        return `In the email, click "Complete confirmation" and finish tasks in the next page.`;
    }
  }

  onBtnRefresh() {
    this.refresh.emit();
  }

  getDelayLabel(item: number) {
    return `${item} mins`;
  }

  // save log
  completedCheckListItem(item) {
    let actions = [];
    switch (item) {
      case 'confirmReady': 
        actions = ['markEquipmentReady']; break;
      case 'confirmDriverReady':
        actions = ['markDriverReady']; break;
      case 'assignDriver': 
        actions = ['markDriverReady']; break;
      default: break;
    }
    if (actions?.length > 0) {
      this.updateLogHistory({ actions });
    }
  }

}