import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { InputHelper } from "@services/input-helper";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { NzIconModule } from "ng-zorro-antd/icon";

export interface ContactInfo {
  name?: string,
  firstName?: string,
  lastName?: string,
  phone?: string,
  email?: string,
  entity?: 'Driver' | 'Carrier'
}

const CustomInputs = [
  NgIf,
  NzIconModule
]
@Component({
  selector: '[contact-driver-box]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class ContactDriverComponent {
  @Input() contactInfo: ContactInfo = null;

  public avtCharacter: string = '';
  public contactPhone: string = '';
  public contactName: string = '';
  public contactEmail: string = '';
  public contactEntity: 'Driver' | 'Carrier' = 'Driver';

  ngOnChanges() {
    this.buildDisplayInfo();
  }

  buildDisplayInfo() {
    this.contactEntity = this.contactInfo?.entity || 'Driver';
    let fullName = this.getContactName(this.contactInfo);
    this.avtCharacter = this.getAvatarCharacter(fullName);
    this.contactName = this.getContactName(this.contactInfo);
    this.contactPhone = this.getContactPhone(this.contactInfo);
    this.contactEmail = this.getContactEmail(this.contactInfo);
  }

  getAvatarCharacter(fullName) {
    if (!fullName) return 'N/A';
    let items = fullName.split(' ');
    let lastName = items[items.length - 1];
    let firstC = lastName.slice(0, 1);
    return firstC.toUpperCase() || ''
  }

  getContactName(contact: ContactInfo) {
    if (contact?.name) return contact.name;
    return `${contact?.firstName || ''} ${contact?.lastName || ''}`.trim();
  }
  getContactPhone(contact: ContactInfo) {
    return InputHelper.formatPhone(contact?.phone);
  }

  getContactEmail(contact: ContactInfo) {
    return contact?.email || '';
  }

  copyToClipboard(value) {
    Utils.copyTextToClipboard(value, (e) => {
      if (e) {
        UIHelper.showErr("Cannot copy to clipboard");
      } else {
        UIHelper.showSuccess("Copy to clipboard successfully");
      }
    })
  }
}