import { CommonModule, NgFor, NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { InputHelper } from "@services/input-helper";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";

const CustomInputs = [
    NgIf, NgFor,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzButtonModule,
]

@Component({
    selector: '[create-driver-task-route-confirmation]',
    standalone: true,
    imports: CustomInputs,
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})

export class CreateDriverTaskRouteConfirmation extends BaseFormDialog {
    static get driverProfileDeclaration() {
        return {
          firstName: { label: "First name", required: true },
          lastName: { label: "Last name", required: true },
          phone: {
            label: "Phone",
            inputType: "tel",
            required: true,
            getValue: InputHelper.getValuePhone,
            formatValue: InputHelper.formatPhone,
          },
        };
    }
    protected formGroupDeclaration: FormGroupDeclaration = {
        driverProfile: {
          label: "",
          type: "formGroup",
          childItem: CreateDriverTaskRouteConfirmation.driverProfileDeclaration,
          required: true
        }
    };
    public onProgress = false;
    public carrierId: string;
    constructor(@Inject(NZ_MODAL_DATA) data: any) {
        super(data);
        this.carrierId = data.carrierId;
    }

    get isCreateNew(): boolean {
        return true;
    }

    get btnSubmitLabel(): string {
        return "Add & Assign";
    }
    
    get btnCancelLabel(): string {
        return "Close";
    }
    

    get canClickOK() {
        return this.needUpdate && !this.onProgress;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    onBtnSave(): void {
        if (!this.needUpdate) {
            return;
        }
        let json: any = this.getFormData_JSON(true);
        this.createDriver(json.driverProfile);
    }
    private createDriver(params) {
        this.onProgress = true;
        params.carrierId = this.carrierId;
        this.api.POST(Const.APIURI_DRIVERS, params).subscribe(
            (resp) => {
                this.onProgress = false;
                this.onCreateSuccess(resp);
            },
            (err) => {
                UIHelper.showErr(err);
                this.onProgress = false;
            }
        );
    }
}