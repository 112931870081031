import { NgFor, NgIf } from "@angular/common";
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LogAction } from "@app/interfaces";
import { OTPConfirmationAnswerData } from "@app/interfaces/answer-data";
import { BaseTaskAnswer } from "@app/work-dispatcher/task-queue/base/base-task-answer";
import { UIHelper } from "@services/UIHelper";
import { AnswersTaskOTPConfirmation } from "@wearewarp/types/data-model/types/work-queue";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

const CustomInputs = [
  NgIf, NgFor,
  FormsModule,
  NzInputModule,
  NzRadioModule,
  NzGridModule,
  NzButtonModule,
  NzToolTipModule,
  NzDatePickerModule
]

@Component({
  selector: '[answers-for-task-otp-confirmation]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class AnswersForTaskOTPConfirmation  extends BaseTaskAnswer {
  @Input() contactEntity: 'driver' | 'carrier' = 'driver';  // thông tin xem liên hệ với ai -> log action

  @ViewChild('tplMarkDriverConfirmed') tplMarkDriverConfirmed!: TemplateRef<any>;
  @ViewChild('tplMarkIncorrectDriver') tplMarkIncorrectDriver!: TemplateRef<any>;
  @ViewChild('tplMarkContactFailed') tplMarkContactFailed!: TemplateRef<any>;
  @ViewChild('tplMarkContactedViaSms') tplMarkContactedViaSms!: TemplateRef<any>;

  optionArr: AnswersTaskOTPConfirmation[] = [];
  answerType: AnswersTaskOTPConfirmation | undefined = undefined;
  answerData: OTPConfirmationAnswerData = {}
  formAnswers = {
    driverConfirmed: {
      label: 'Driver will be on-time',
      value: 'driverConfirmed',
      desc: '' },
    incorrectDriver: {
      label: 'Incorrect driver',
      value: 'incorrectDriver',
      desc: `No, I'm not the Driver for this Load.` },
    contactFailed: {
      label: 'Contact failed',
      value: 'contactFailed',
      desc: `Follow-up after 10 mins` },
    contactedViaSms: {
      label: 'Contacted via Sms/Email',
      value: 'contactedViaSms',
      desc: `Follow-up after 10 mins` },
  }
  templateController = {
    driverOnTime: { visible: false, trigger: 'click' },
    driverLate: { visible: false, trigger: 'click' },
    driverNotPickup: { visible: false, trigger: 'click' },
    contactFailed: { visible: false, trigger: 'click' },
  }
  needRecovery: boolean = false;
  scheduleTimeWindow; 

  ngOnInit() {
    this.scheduleTimeWindow = this.taskService?.getCurrentStopEntity()?.getScheduleTime();
    this.initAnswerOptions();
  }

  initAnswerOptions() {
    this.optionArr = ['driverConfirmed', 'incorrectDriver', 'contactedViaSms', 'contactFailed'];
  }

  getDesc(key) {
    if (key === this.answerType) {
      if (key === 'contactFailed' && this.answerData?.data?.needRecovery) {
        return `Recovery request will be triggered`;
      }  
    }
    return this.formAnswers[key]?.desc || '';
  }

  getTemplate(status): TemplateRef<any> {
    switch (status) {
      case 'driverConfirmed': return this.tplMarkDriverConfirmed;
      case 'incorrectDriver': return this.tplMarkIncorrectDriver;
      case 'contactFailed': return this.tplMarkContactFailed;
      case 'contactedViaSms': return this.tplMarkContactedViaSms;
    }
  }

  getLogActions(type: AnswersTaskOTPConfirmation): LogAction[] {
    switch (type) {
      case 'driverConfirmed': return ['markConfirmedOTP'];
      case 'incorrectDriver': return ['markIncorrectDriver'];
      case 'contactedViaSms': return ['markContactedViaSms'];
      case 'contactFailed': 
        if (this.contactEntity === 'carrier')
          return ['markContactCarrierFailed'];
        return ['markContactDriverFailed'];
      default: return [];
    }
  }

  updateAnswer(key: AnswersTaskOTPConfirmation) {
    try {
      this.resetAnswerData();
      this.answerType = key;
      this.answerData.type = key;
      if (key === 'driverConfirmed') this.onMarkDriverConfirmed();
      else if (key === 'incorrectDriver') this.onMarkIncorrectDriver();
      else if (key === 'contactFailed') this.onMarkContactFailed();
      else if (key === 'contactedViaSms') this.onMarkContactedViaSms();
      this.onResponseChange(this.answerData);
    }
    catch (e) {
      this.answerType = null;
      UIHelper.showErr(e);
    }
  }

  onMarkDriverConfirmed() {
    this.answerData.data = {};
  }
  onMarkIncorrectDriver() {
    this.answerData.data = {};
  }
  onMarkContactedViaSms() {
    this.answerData.data = {};
  }
  onMarkContactFailed() {
    const histories = this.taskService?.getLogHistory();
    const actions = histories?.map(h => [...(h.actions || [])])?.flat() || [];
    let numCallFailed = actions.filter(a => a === 'markContactDriverFailed' || a === 'markContactCarrierFailed').length;
    // Đến lần thứ 3 thì complete task
    if (numCallFailed >= 2) {
      this.answerData.data = { 
        needRecovery: true, 
        shouldCompleteTask: true
      };
    }
  }

  onTooltipClick(key: AnswersTaskOTPConfirmation) {
    this.updateAnswer(key);
  }
}