import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-util";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TaskService } from "../../services/task.service";
import { Const } from "@const/Const";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { TimeWindow, TrackingTaskType } from "@app/interfaces";
import StopEntity from "../../entity/StopEntity";
import { DeliveryEta } from "@app/work-dispatcher/components/delivery-eta";
import { BaseComponent } from "@abstract/BaseComponent";
import { TaskComponentsVisibiltyService } from "../../services/task-components-visibility.service";
import { RouteService } from "../../services/route.service";
import { CurrentStateType, TaskCurrentStateService } from "./current-state.service";
import { Utils } from "@services/utils";


const CustomInputs = [
  NgIf, NgFor, NgClass,
  NgSwitch, NgSwitchCase, NgSwitchDefault,
  NzGridModule,
  NzIconModule,
  NzButtonModule,
  NzToolTipModule,
  DeliveryEta
]

@Component({
  selector: 'task-overview',
  standalone: true,
  imports: [CustomInputs],
  providers: [TaskCurrentStateService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskOverview extends BaseComponent {
  constructor(
    private taskService: TaskService,
    private routeService: RouteService,
    private visibilityService: TaskComponentsVisibiltyService,
    private currentStateService: TaskCurrentStateService
  ) {
    super();
  }

  private _taskTitle;
  @Input() set taskTitle(value) {
    this._taskTitle = value;
  }
  get taskTitle() {
    return this._taskTitle;
  }

  private timerReloadData;
  protected routeData;
  protected currentStop: StopEntity;
  public taskType: TrackingTaskType;
  public displayRouteInfos: any = null;
  public displayTaskInfos: any = null;
  public stopScheduleTime;
  public currentState: CurrentStateType = 'ontime';
  public currentTime: Date;
  public displayTaskLabelsArr: string[] = [];     // Mỗi task sẽ hiển thị những field khác nhau
  public representativeTask: any = null;
  public liveETAWindow: TimeWindow;
  public shipmentItems;
  public isEnableLiveTracking: boolean = false;

  ngOnInit() {  
    this.currentTime = new Date();  
    this.loadRouteData();
    this.buildDisplayRouteInfos();
    this.buildDisplayTaskInfos();

    this.timerReloadData = setInterval(() => {
      this.currentTime = new Date();
      this.buildMoreLabelTaskInfos();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
    }
  }

  onChangeRouteDetailVisibility() {
    this.visibilityService.changeRouteDetailVisibility();
  }

  loadRouteData() {
    this.taskType = this.taskService.getTaskType();
    this.routeData = this.routeService.getRoute().getData();
    this.currentStop = this.taskService.getCurrentStopEntity();
    this.stopScheduleTime = this.currentStop?.getScheduleTime();
    this.representativeTask = this.currentStop?.getRepresentativeTask();
    this.shipmentItems = this.routeService.getRoute()?.getShipmentItems();
    this.isEnableLiveTracking = this.routeService.getLiveTrackingStatus();
  }

  getDisplayTaskLabelsArr() {
    let items = [];
    switch (this.taskType) {
      case 'arrivalConfirmation': items = ['currentTime', 'schedule', 'driverETA', 'currentState']; break;
      case 'departureConfirmation': items = ['currentTime', 'arrivalTime', 'currentState']; break;
      case 'routeConfirmation': items = ['currentTime', 'schedule']; break;
      case 'otpConfirmation': items = ['currentTime', 'schedule']; break;
      case 'etaConfirmation': items = ['currentTime', 'schedule', 'driverETA', 'liveETA', 'currentState']; break;
    }
    if (items.includes('liveETA') && !this.isEnableLiveTracking) {
      items = items.filter(it => it !== 'liveETA');
    }
    return items;
  }

  buildDisplayRouteInfos() {
    this.displayRouteInfos = {
      code: this.routeData?.code || '',
      status: Const.getJobStatusText(this.routeData?.status),
      origin: this.getFirstPickup(),
      destination: this.getLastDropoff(),
      numOfStop: this.getNumOfStops(),
      clients: this.buildDisplayClients(),
      carrier: this.getCarrierName(),
      equipment: this.getEquipments(),
      items: this.buildDisplayItems(),
    }
  }

  buildDisplayTaskInfos() {
    let stopStatus = this.currentStop?.getStatus();
    let stopIndex = this.currentStop?.getDisplayIndex();
    let locationName = this.currentStop?.getLocationName() || '';
    let address = this.currentStop?.getAddressText() || 'N/A';
    let type = Const.getTaskTypeText(this.currentStop?.getType());

    this.displayTaskLabelsArr = this.getDisplayTaskLabelsArr();
    this.displayTaskInfos = {
      status: Const.getStopStatusText(stopStatus),
      stopIndex: stopIndex,
      locationName: locationName,
      address: address,
      type: type,
    }
    this.buildMoreLabelTaskInfos();
  }

  buildMoreLabelTaskInfos() {
    // Hiển thị thông tin riêng cho từng loại task
    let moreTaskLabelsValue = {};
    switch (this.taskType) {
      case 'arrivalConfirmation':
        moreTaskLabelsValue = {
          currentTime: this.getDisplayCurrentTime(),
          schedule: this.getDisplayScheduleTime(),
          driverETA: this.getDisplayDriverETATime(),
          currentState: this.getDisplayCurrentState()
        }
        break;
      case 'departureConfirmation':
        moreTaskLabelsValue = {
          currentTime: this.getDisplayCurrentTime(),
          arrivalTime: this.getDisplayArrivalTime(),
          currentState: this.getDisplayCurrentState()
        }
        break;
      case 'routeConfirmation':
        moreTaskLabelsValue = {
          currentTime: this.getDisplayCurrentTime(),
          schedule: this.getDisplayScheduleTime(),
        }
        break;
      case 'etaConfirmation':
        moreTaskLabelsValue = {
          currentTime: this.getDisplayCurrentTime(),
          schedule: this.getDisplayScheduleTime(),
          driverETA: this.getDisplayDriverETATime(),
          currentState: this.getDisplayCurrentState()
        }
        break;
      case 'otpConfirmation':
        moreTaskLabelsValue = {
          currentTime: this.getDisplayCurrentTime(),
          schedule: this.getDisplayScheduleTime(),
        }
    }
    this.displayTaskInfos = {
      ...this.displayTaskInfos,
      ...moreTaskLabelsValue
    }
  }
  
  getCurrentStopTimeZone() {
    return this.currentStop?.getTimeZoneStandard() || '';
  }
  
  onBtnRefresh() {
    this.router.navigate([this.currentUrl]);
  }

  getTextLabel(key) {
    switch (key) {
      case 'clients': return 'Customers';
      case 'carrier': return 'Carrier';
      case 'equipment': return 'Equipment';
      case 'items': return 'Items';
      case 'currentTime': return 'Current time';
      case 'schedule': return 'Schedule';
      case 'liveETA': return 'ETA';
      case 'driverETA': return 'Driver ETA';
      case 'currentState': return 'Status';
      case 'arrivalTime': return 'Arrival time';
    }
  }

  // Tính theo timezone của stop hiện tại
  private getDisplayTime(time, format = 'MMM DD, YYYY h:mm A') {
    if (!time) return 'N/A';
    let timezone = this.getCurrentStopTimeZone();
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime =  DateUtil.displayLocalTime(time, timezone, format);
    return `${localTime} ${shortTz}`.trim();
  }

  private getDisplayTimeWindow(timeWindow) {
    if (!timeWindow) return 'N/A';
    let timezone = this.getCurrentStopTimeZone();
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let windowStr = DateUtil.displayTimeWindow(timeWindow, {
      timezone: timezone,
      format: 'MMM DD, YYYY h:mm A',
      formatDateOnly: 'MMM DD, YYYY'
    })
    return `${windowStr} ${shortTz}`.trim();
  }

  getDisplayCurrentTime() {
    return this.getDisplayTime(this.currentTime, 'MMM DD, YYYY h:mm:ss A');
  }

  getDisplayScheduleTime() {
    let scheduleWindow = this.stopScheduleTime;
    return this.getDisplayTimeWindow(scheduleWindow);
  }

  getDisplayDriverETATime() {
    let eta = this.currentStop?.getETA();
    if (!eta) return 'N/A';
    return this.getDisplayTimeWindow(eta);
  }

  getDisplayArrivalTime() {
    return this.getDisplayTime(this.currentStop?.getArrivedTime());
  }

  getFirstPickup() {
    let stops = this.routeData?.stops || [];
    return this.getShortDisplayLocation(stops[0]);
  }

  getLastDropoff() {
    let stops = this.routeData?.stops || [];
    return this.getShortDisplayLocation(stops[stops.length - 1]);
  }

  getShortDisplayLocation(stop) {
    if (!stop) return '';
    let city = stop?.info?.addr?.city || '';
    let state = stop?.info?.addr?.state || '';
    return `${city}, ${state}`;
  }

  getNumOfStops() {
    return (this.routeData?.stops || []).length;
  }

  getListClientsName() {
    return this.routeData?.clients?.map(it => it.name) || [];
  }

  getClientsTooltipTitle() {
    let clients = this.getListClientsName();
    return clients.join(', ');
  }

  buildDisplayItems() {
    let items = this.shipmentItems || [];
    let totalWeight = 0;
    let totalPallet = 0;
    for (let item of items) {
      // Hiện tại chỉ tính theo lbs
      if (item?.weightUnit === 'lbs') {
        let qty = Utils.toNumber(item.qty, 0);
        let weightPerUnit = Utils.toNumber(item.weightPerUnit, 0)
        totalWeight += qty * weightPerUnit;
        totalPallet += qty;
      }
    }
    return `${totalWeight} lbs, ${totalPallet} ${totalPallet > 1 ? 'pallets' : 'pallet'}`;
  }

  getEquipments() {
    let vehicle = this.routeData?.requiredVehicle;
    if (!vehicle) return 'N/A';
    if (vehicle.options?.length) {
      return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`;
    }
    return vehicle.name;
  }

  buildDisplayClients() {
    let clients = this.getListClientsName();
    if (clients.length == 0) return 'N/A';
    if (clients.length == 1) return clients[0];
    let numOfHidden = clients.length - 2;
    let text = `${clients[0]} - ${clients[1]}`;
    if (numOfHidden) text = `${text} (+${numOfHidden})`;
    return text;
  }

  getCarrierName() {
    let carrier = this.routeData?.carrier;
    return carrier?.basicInfo?.name || 'N/A';
  }

  getDisplayCurrentState() {
    switch (this.taskType) {
      case 'arrivalConfirmation': return this.getCurrenStateForTaskConfirmArrival();
      case 'departureConfirmation': return this.getCurrenStateForTaskConfirmDeparture();
      case 'etaConfirmation': return this.getCurrenStateForTaskConfirmETA();
    }
  }

  getCurrenStateForTaskConfirmArrival() {
    // return 'N/A';
    let currentLocalTime = DateUtil.convertLocalTime(this.currentTime, DateUtil.getLocalTimeZone());
    let currentIsoTime = currentLocalTime.toISOString();
    let duration: number = 0;
    let tmpMinutes: number = 0;
    let windowOntime: TimeWindow = {
      from: currentIsoTime,
      to: this.stopScheduleTime?.from
    }
    let windowLate: TimeWindow = {
      from: this.stopScheduleTime?.to,
      to: currentIsoTime
    }
    duration = DateUtil.getTimeDurationFromWindow(windowLate);
    if (duration > 0) {
      tmpMinutes = duration / (1000*60);
      this.currentState = 'lated';
    } else {
      duration = DateUtil.getTimeDurationFromWindow(windowOntime);
      if (duration < 0) {
        // current time > schedule.from
        duration = -duration;
        tmpMinutes = duration / (1000*60);
      }
      this.currentState = 'ontime';
    }
    tmpMinutes = Math.round(tmpMinutes);
    let detailTimeStr = this.getParseTimeMinutes(tmpMinutes)

    if (this.currentState === 'ontime') {
      let detailTxt = tmpMinutes > 0 ? `(${detailTimeStr} past start time)` : '';
      return `On time ${detailTxt}`.trim();
    }
    else {
      return `Already late ${detailTimeStr}`.trim();
    }
  }

  getCurrenStateForTaskConfirmDeparture() {
    // return 'N/A';
    let arrivalTime = this.currentStop?.getArrivedTime();
    if (!arrivalTime) return '';
    let currentLocalTime = DateUtil.convertLocalTime(this.currentTime, DateUtil.getLocalTimeZone());
    let currentIsoTime = currentLocalTime.toISOString();
    let duration: number = 0;
    let tmpMinutes: number = 0;
    let windowLate: TimeWindow = {
      from: arrivalTime,
      to: currentIsoTime
    }
    duration = DateUtil.getTimeDurationFromWindow(windowLate);
    if (duration > 0) {
      tmpMinutes = duration / (1000*60);
      this.currentState = 'lated';
    } 
    else this.currentState = 'ontime';

    tmpMinutes = Math.round(tmpMinutes);
    let detailTimeStr = this.getParseTimeMinutes(tmpMinutes)
    
    if (this.currentState === 'ontime') {
      return 'On time';
    }
    else {
      return `Driver has been at the stop for ${detailTimeStr}`.trim();
    }
  }

  getCurrenStateForTaskConfirmETA() {
    let currentLocalTime = DateUtil.convertLocalTime(this.currentTime, DateUtil.getLocalTimeZone());
    let currentIsoTime = currentLocalTime.toISOString();
    let etaWindow = this.liveETAWindow;
    if (!etaWindow) {
      // Không có liveETA thì lấy driver ETA
      etaWindow = this.currentStop?.getETA();
    }
    const data = this.currentStateService.getCurrentStateForTaskConfirmETA({
      scheduleWindow: this.stopScheduleTime,
      etaWindow,
      currentIsoTime
    })
    if (!data || !data.state) return 'N/A';
    this.currentState = data.state;
    let tmpMins = Math.round(data.duration / (1000*60));
    let detailTimeStr = this.getParseTimeMinutes(tmpMins);
    if (data.state === 'ontime') return `Might be on time${tmpMins > 0 ? ` (${detailTimeStr} past start time)` : ''}`;
    else if (data.state === 'might_be_late') return `Might be late (${detailTimeStr} past end time)`;
    else if (data.state === 'lated') return `Already late (${detailTimeStr} past end time)`;
    return 'N/A';
  }

  getParseTimeMinutes(mins) {
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    return `${hours} hr ${minutes} m`;
  }

  onChangeLiveETA(eta) {
    this.liveETAWindow = eta?.window;
  }

  get isTaskCompleted() {
    return this.taskService.getTaskStatus() === Const.WorkTaskStatus.completed;
  }
}