import { Const } from "@const/Const";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

class UserPrefService {
    api: ApiService = null
    baseUrl: string = null
    private user: any = null
    cache: any = null

    constructor(api?: ApiService) {
        this.api = api
        this.baseUrl = Const.APIV2(`metadata`)
    }

    setUser(user) {
        if (user?.id === this.user?.id) return
        this.user = user
        this.cache = {}

        // pre-pull
        if (!user) return
        const url = `${this.baseUrl}/USER_${this.user.id}/USER_PREFERENCES`
        this.api.GET(url).subscribe((res) => {
            if (res) {
                this.cache = { ...this.cache, ...res }
            }
        })
    }

    getPref(name: string): Observable<any> {
        if (!this.user)
            return new Observable((observer) => observer.next(null))
        if (this.cache[name]) {
            return new Observable((observer) => observer.next(this.cache[name]))
        }
        const url = `${this.baseUrl}/USER_${this.user.id}/USER_PREFERENCES/${name}`

        return this.api.GET(url)
            .pipe(
                map(data => {
                    this.cache[name] = data.value
                    return data.value;
                })
            )
    }

    setPref(name: string, value: string) {
        const url = `${this.baseUrl}/USER_${this.user.id}/USER_PREFERENCES/${name}`
        this.api.POST(url, { value }).subscribe((res) => {
            this.cache[name] = value
        })
    }
}

const userPref = new UserPrefService()

export const getUserPreferences = (api?: ApiService, user?) => {
    if (api)
        userPref.api = api
    if (user)
        userPref.setUser(user)
    return userPref
}