import polyline from '@mapbox/polyline';
import { CompositeLayer,  PathLayer } from 'deck.gl';
import _ from 'underscore'

export default class PolylineLayer extends CompositeLayer {
    id: string
    static layerName = "PolylineLayer"
    points: any[] = []
    options: any = {}
    job: any = {}

    constructor(id, data, options: any = {}) {
        super({id, data: data})
        if (data.line) {
            this.points = polyline.decode(data.line)
        } else {
            this.points = data.points
        }
        this.id = id
        this.options = options
        this.job = data?.job ?? {}
    }

    createPathLayer() {
        if (!this.points?.length) return null
        const points = this.points.map(it => [it[1], it[0]]);
        const id = this.id + '-polyline';
        const data = [{
            coordinates: points,
            id,
            job: this?.job
        }]
        return new PathLayer({
            data,
            id,
            pickable: true,
            widthScale: 1,
            getPath: d => d.coordinates,
            getColor: d => this.options.color || [93, 93, 253],
            getWidth: d => this.options.width || 4,
            opacity: this.options.opacity || 1.0,
            widthMinPixels: this.options.minWidth || 3,
            widthMaxPixels: this.options.maxWidth || 6,
            jointRounded: true,
            capRounded: true,
        });
    }

    renderLayers() {
        return [this.createPathLayer()];
    }
}