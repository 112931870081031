import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Subject } from "rxjs";
import { ApiService } from "@services/api.service";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { ActionLogHistory, LogAction, RequestCompleteTask } from "@app/interfaces";
import StopEntity from "../entity/StopEntity";
import { getApp } from "@services/index";
import { DateUtil } from "@services/date-util";
import RouteEntity from "../entity/RouteEntity";
import { RouteService } from "./route.service";
import { UIHelper } from "@services/UIHelper";

type ObjectType = 'JOB';

@Injectable({
  providedIn: 'root'
})
/**
 * Service to manage task queue
 * Just handle 1 task at a time
 */
export class TaskService {
  public taskInfo: BehaviorSubject<any> = new BehaviorSubject(null)
  private route: RouteEntity = null;
  private taskId: BehaviorSubject<string> = new BehaviorSubject(null);
  private currentStop: StopEntity;
  private oldLogHistory: ActionLogHistory[] = [];
  private newLogHistory: ActionLogHistory[] = [];
  private answerData: any = null;     // Kết qủa phản hồi cho task

  public message: Subject<{ type: string, message: string }> = new Subject();
  public loading: Subject<boolean> = new BehaviorSubject(false);
  
  constructor(private api: ApiService, private routeService: RouteService) {
  }

  resetData() {
    this.taskInfo.next(null);
    this.route = null;
    this.currentStop = null;
    this.answerData = null;
    this.oldLogHistory = [];
    this.newLogHistory = [];
  }

  getTask() {
    return this.taskInfo.getValue() || null;
  }

  getTaskId() {
    return this.taskId.getValue();
  }

  getTaskType() {
    let task = this.getTask();
    return task?.type;
  }

  getTaskStatus() {
    let task = this.getTask();
    return task?.status;
  }
  
  getRouteEntity() {
    return this.route;
  }

  getCurrentStopEntity() {
    return this.currentStop;
  }

  async regenerateCurrentTaskImmediately() {
    try {
      let taskId = this.getTaskId();
      let updateLogUrl = `${environment.backendWorkQueueUrl}/${Const.API_UPDATE_LOG_HISTORY(taskId)}`;
      let params = { histories: this.newLogHistory || [] };
      // lưu lại log trước khi regenerate task
      await lastValueFrom(this.api.POST(updateLogUrl, params));

      const task = this.getTask();
      this.resetData();
      this.taskInfo.next(task);
      this.taskId.next(task?.id);
      Log.d("Re-generate current task: ", this.getTask());
      await this.fetchLogHistory();
      await this.fetchObjectData();
    }
    catch (e) {
      this.message.next({ type: 'error', message: e.message})
    }
  }

  // Phục vụ cho manager làm task escalated hay task cụ thể
  // Hiện chỉ manager có thể gọi function này
  async fetchSpecificTask(taskId) {
    if (!taskId) {
      UIHelper.showErr(`Invalid taskId ${taskId}`);
      return;
    }
    try {
      this.resetData();
      let url = `${environment.backendWorkQueueUrl}/${Const.API_GET_SPECIFIC_TASK(taskId)}`;
      const resp = await lastValueFrom(this.api.GET(url));
      this.taskInfo.next(resp.data);
      this.taskId.next(resp.data?.id);
      Log.d("Fetched new task: ", this.getTask());
      await this.fetchLogHistory();
      await this.fetchObjectData();
    }
    catch (e) {
      this.message.next({ type: 'error', message: e.message})
    }
  }

  async fetchNewTask() {
    try {
      this.resetData();
      let url = `${environment.backendWorkQueueUrl}/${Const.API_FETCH_TASK}`;
      const resp = await lastValueFrom(this.api.GET(url));
      this.taskInfo.next(resp.data);
      this.taskId.next(resp.data?.id);
      Log.d("Fetched new task: ", this.getTask());
      await this.fetchLogHistory();
      await this.fetchObjectData();
    }
    catch (e) {
      this.message.next({ type: 'error', message: e.message})
    }
  }

  async fetchLogHistory() {
    let taskId = this.getTaskId();
    if (!taskId) return;
    let url = `${environment.backendWorkQueueUrl}/${Const.API_GET_LOG_HISTORY(taskId)}`;
    try {
      const resp = await lastValueFrom(this.api.GET(url));
      this.oldLogHistory = resp.data?.list_data || [];
    }
    catch (e) {
      Log.d("Fetch log history error: ", e.message);
    }
  }

  async fetchObjectData() {
    let taskInfo = this.getTask();
    if(!taskInfo) return;
    let objectId = taskInfo?.object?.uid;
    let object: ObjectType = objectId?.split('_')?.[0];
    if (objectId.length <= object.length + 1) {
      this.message.next({
        type: 'error',
        message: 'Invalid objectId'
      })
    }
    objectId = objectId.slice(object.length + 1);
    if (object === 'JOB') {
      // Fetch data using routeService
      await this.routeService.setRouteId(objectId)
      this.route = this.routeService.getRoute();
      let stopId = taskInfo?.object?.metadata?.stopId;
      if (stopId) {
        this.currentStop = this.routeService.getRoute().getStopById(stopId);
      }
    }
  }

  // Sort theo log mới nhất
  getLogHistory(): ActionLogHistory[] {
    let newLogs = this.getNewLogHistory();
    return [...newLogs, ...this.oldLogHistory];
  }

  getNewLogHistory(): ActionLogHistory[] {
    let sortedLogs = [...this.newLogHistory].reverse();
    return [...sortedLogs];
  }

  updateLogHistory(newLog: ActionLogHistory) {
    if (!newLog.update) {
      const authUser = getApp().getAuthUser();
      newLog.update = {
        byId: authUser?.id,
        by: authUser?.warpId,
        collection: Const.UpdateByCollection.users,
        when: DateUtil.getLocalCurrentTimeISO()
      }
    }
    // Nếu log lặp lại liền nhau thì lấy log mới
    let len = this.newLogHistory?.length;
    let latestLog = len ? this.newLogHistory[len - 1] : null;
    if (latestLog?.type === newLog?.type &&  latestLog?.actions?.length && latestLog.actions?.length === newLog.actions?.length) {
      let differs = latestLog.actions?.filter(it => !newLog.actions?.includes(it));
      if (!differs?.length) {
        this.newLogHistory[len - 1] = newLog;
        return;
      }
    }
    this.newLogHistory = [...this.newLogHistory, newLog];
  }

  updateAnswerData(data: any) {
    this.answerData = data;
  }

  // Lưu thẳng log vào history, không cần complete task mới save
  async saveLogActions(actions: LogAction[]) {
    let taskId = this.getTaskId();
    let log: ActionLogHistory = { actions }
    let params = { histories: [log] }
    let updateLogUrl = `${environment.backendWorkQueueUrl}/${Const.API_UPDATE_LOG_HISTORY(taskId)}`;
    await lastValueFrom(this.api.POST(updateLogUrl, params));
    await this.fetchLogHistory();
  }

  getParamsToCompleteTask() {
    let taskType = this.getTaskType();
    switch (taskType) {
      case Const.TrackingTask.arrivalConfirmation:
      case Const.TrackingTask.departureConfirmation: // params giống với arrivalConfirmation
      case Const.TrackingTask.routeConfirmation:
      case Const.TrackingTask.otpConfirmation:
      case Const.TrackingTask.etaConfirmation:
        return this.getBasicParamsForCompleteTask();
      default:
        return this.getBasicParamsForCompleteTask();
    }
  }

  getBasicParamsForCompleteTask() {
    let params: RequestCompleteTask = {
      answer: this.answerData,
      histories: this.newLogHistory || []     // Chỉ gửi log mới
    }
    return params;
  }
}