import { CompositeLayer, IconLayer, ScatterplotLayer } from 'deck.gl';
import _, { first } from 'underscore'
import { LOCATION_PIN_ICON_MAPPING } from './warehouse_pin_mapping';
import PolylineLayer from './polyline_layer';
import Utils from './utils';
import { BizUtil } from '@services/biz';

export default class JobLayer extends CompositeLayer {
    id: string
    static layerName = "JobLayer"
    data: {job?, tasks?, line?, shipments?}
    selectRoute: (s: string) => void
    color: number[]
    options: any = {}

    constructor(id, data: {job?, tasks?, line?, shipments?}, options: any = {}) {
        super({id, data: data})
        this.data = data
        this.id = id
        this.data = data
        this.options = options
    }

    public static getLnglat(task) {
        const { location } = task || {}
        if (location?.latitude) {
            return [location.longitude, location.latitude]
        }
        const { lat, lng } = location?.latlng || {}
        return [lng, lat]
    }

    public static getLatLng(task) {
        const { location } = task || {}
        if (location?.latitude) {
            return [location.latitude, location.longitude]
        }
        const { lat, lng } = location?.latlng || {}
        return [lat, lng]
    }

    createStopLayer() {
        if (!this.data?.tasks?.length) return null

        // TODO group by location
        let shipmentMap: any = {}
        for (let s of this.data.shipments || []) {
            shipmentMap[s.id] = s
        }
        for (let t of this.data.tasks) {
            const { location } = t
            t.locationId = Utils.latLngId(location)
            t.shipment = shipmentMap[t.shipmentId]
        }

        const getTaskInfo = (task) => {
            const { type, status } =  task
            let info = `${task.type} ${(task.shipment?.code ?? task.shipment?.warpId) || ''}`
            if (status) {
                info += ` ${status}`
            }
            return info
        }

        const grouped = _.values(_.groupBy(this.data.tasks || [], it => it.locationId))
        let data = grouped.map(l => {
            const pickups = l.filter(it => it.type == 'PICKUP')
            const dropoffs = l.filter(it => it.type == 'DROPOFF')
            const shipment = l[0].shipment
            let info = null
            if (shipment) {
                const deliveryInfo = l[0].type === 'PICKUP' ? BizUtil.getPickInfo(shipment) : BizUtil.getDropInfo(shipment)
                const {street, city, zipcode, state} = deliveryInfo?.addr || {}
                const locationName = deliveryInfo.locationName
                const formatted = (locationName ? `${locationName}\n` : '') + `${street}\n${city}, ${state} ${zipcode}`
                info = formatted + '\n' +
                    l.map(getTaskInfo).join("\n")
            }
            const iconType = dropoffs.length ? '' : 'Up'
            const iconColor = (l[0].status === 'succeeded') ? 'green' :
                (l[0].status === 'failed' || l[0].status === 'failedPickup') ? 'red' :
                (l[0].status === 'arrived') ? 'orange' :
                (l[0].status === 'inProgress' || l[0].status === 'enroute') ? 'blue' : 'gray'
            const icon = iconColor + iconType
            return {
                coordinates: JobLayer.getLnglat(l[0]),
                type: 'TASKS',
                info,
                task: l,
                icon
            }
        })
        data = _.sortBy(data, it => -it.coordinates[1])

        return new IconLayer({
            id: `${this.id}-icon-layer`,
            data,
            pickable: true,
            iconAtlas: '/assets/svg/location_pin.svg',
            iconMapping: LOCATION_PIN_ICON_MAPPING,
            getIcon: d => d.icon,
            sizeScale: 1,
            getPosition: d => [...d.coordinates, 0.001],
            getSize: d => 27,
        });
    }

    createPathLayer() {
        if (this.data?.line)
            return new PolylineLayer(this.id + '-path', {line: this.data.line, job: this.data?.job}, {color: this.options?.pathLayer})
        if (!this.data?.tasks?.length) return null
        const points = this.data.tasks.map(it => JobLayer.getLatLng(it))
        return new PolylineLayer(this.id + '-path', {points, job: this.data?.job}, {color: this.options?.pathLayer})
    }

    createEndingDotLayer() {
        const firstTask = (this.data.tasks || [])[0]
        if (!firstTask) return null
        const lastTask = this.data.tasks[this.data.tasks.length - 1]
        const locations = [{
            coordinates: JobLayer.getLnglat(firstTask),
            color: [0, 0, 200]
        },{
            coordinates: JobLayer.getLnglat(lastTask),
            color: [200, 60, 0]
        }]

        return new ScatterplotLayer({
            id: `${this.id}-start-location-layer`,
            data: locations,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 8,
            radiusMinPixels: 8,
            radiusMaxPixels: 8,
            lineWidthMinPixels: 3,
            lineWidthMaxPixels: 3,
            getPosition: d => d.coordinates,
            getFillColor: d => [255,255,255],
            getLineColor: d => d.color
        });
    }

    renderLayers() {
        return [this.createEndingDotLayer(), this.createPathLayer(),this.createStopLayer()];
    }
}