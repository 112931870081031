import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { DateUtil } from "@services/date-util";
import { NzInputModule } from "ng-zorro-antd/input";
import { UIHelper } from "@services/UIHelper";
import { DriverStatus, LogAction } from "@app/interfaces";
import { DriverAnswerData } from "@app/interfaces/answer-data";
import { ContactInfo } from "../contact-driver-box";
import { BaseTaskAnswer } from "../base-task-answer";


const CustomInputs = [
  NgIf, NgFor,
  FormsModule,
  NzInputModule,
  NzDatePickerModule,
  NzRadioModule,
  NzGridModule,
  NzButtonModule,
  NzToolTipModule,
]

@Component({
  selector: '[driver-status]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class DriverResponseComponent extends BaseTaskAnswer {
  @Input() markOptions: DriverStatus[] = [];
  @Input() contactInfo: ContactInfo = null;     // Nếu cần xác định đối tượng liên hệ là ai? Driver hay Carier
  @Output() driverResponseChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tplMarkDriverNotArrived') tplMarkDriverNotArrived!: TemplateRef<any>;
  @ViewChild('tplMarkDriverArrived') tplMarkDriverArrived!: TemplateRef<any>;
  @ViewChild('tplMarkDriverHasLeft') tplMarkDriverHasLeft!: TemplateRef<any>;
  @ViewChild('tplMarkContactFailed') tplMarkContactFailed!: TemplateRef<any>;
  @ViewChild('tplMarkDriverNotFinishedLoading') tplMarkDriverNotFinishedLoading!: TemplateRef<any>;
  @ViewChild('tplMarkDriverDeparted') tplMarkDriverDeparted!: TemplateRef<any>;

  driverStatus: DriverStatus | undefined = undefined;
  driverStatusArr = ['driverNotArrived', 'driverOnSite', 'driverHasLeft', 'driverNotFinishedLoading', 'driverNotFinishedUnloading', 'driverDeparted', 'contactFailed'];
  displayStatusArr: any = [];
  formDriverStatus = {
    driverNotArrived: { label: "Driver Hasn't Arrived", value: 'driverNotArrived', desc: `Ask: "Can you give me ETA?"` },
    driverOnSite: { label: "Driver on-site", value: 'driverOnSite', desc: `Ask: "Can you give me actual time arrival?"` },
    driverHasLeft: { label: "Driver Has Left", value: 'driverHasLeft', desc: `Ask: "Can you give me actual time arrival?"` },
    driverNotFinishedLoading: { label: "Driver hasn't finished loading", value: 'driverNotFinishedLoading', desc: `Ask\n1. "Can I know the reason for the delay?"\n2. "What time will you finish?"` },
    driverNotFinishedUnloading: { label: "Driver hasn't finished unloading", value: 'driverNotFinishedUnloading', desc: `Ask\n1. "Can I know the reason for the delay?"\n2. "What time will you finish?"` },
    driverDeparted: { label: "Driver has finished loading", value: 'driverDeparted', desc: `Tell Driver "Could you please confirm departed successfully on driver app.?"\n\n<span class="light-text">If they don't</span>\n\nAsk "Can you give me check out time?"\nthen confirm here` },
    contactFailed: { label: "Contact Failed", value: 'contactFailed', desc: "Enter note" },
  }
  visibleTemplate = {
    driverNotArrived: false,
    driverOnSite: false,
    driverHasLeft: false,
    driverNotFinishedLoading: false,
    driverNotFinishedUnloading: false,
    driverDeparted: false,
    contactFailed: false,
  }
  answerData: DriverAnswerData = {};
  tmpNoteContactFailed: string = '';    // Lưu note tạm thời
  tmpNoteDelayReason: string = '';
  tmpETA;
  tmpATA;
  tmpDepartureTime;
  tmpEstimateDepartureTime;

  ngOnInit(){
    this.buildDisplayStatusArr();
  }

  ngOnChange() {
    this.buildDisplayStatusArr();
  }

  get spanOfItem() {
    let numOfItems = this.displayStatusArr.length || 3;
    return 24 / numOfItems;
  }

  buildDisplayStatusArr() {
    this.displayStatusArr = [];
    // Sort các option theo thứ tự cố định
    if (this.markOptions.includes('driverNotArrived')) this.displayStatusArr.push('driverNotArrived');
    if (this.markOptions.includes('driverOnSite')) this.displayStatusArr.push('driverOnSite');
    if (this.markOptions.includes('driverHasLeft')) this.displayStatusArr.push('driverHasLeft');
    if (this.markOptions.includes('driverNotFinishedLoading')) {
      this.displayStatusArr.push('driverNotFinishedLoading');
      this.formDriverStatus.driverDeparted.label = "Driver has finished loading";
    }
    if (this.markOptions.includes('driverNotFinishedUnloading')) {
      this.displayStatusArr.push('driverNotFinishedUnloading');
      this.formDriverStatus.driverDeparted.label = "Driver has finished unloading";
    }
    if (this.markOptions.includes('driverDeparted')) this.displayStatusArr.push('driverDeparted');
    if (this.markOptions.includes('contactFailed')) this.displayStatusArr.push('contactFailed');
  }

  getValue(key) {
    return this.formDriverStatus[key]?.value || '';
  }

  getLabel(key) {
    return this.formDriverStatus[key]?.label || '';
  }

  // Input là thời gian lấy từ server, không phải thời gian theo timezone browser
  getDisplayLocalTime(originTime) {
    let timezone = this.getCurrentStopTimeZone();
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime = DateUtil.displayLocalTime(originTime, timezone, 'MMM DD, YYYY h:mm A');
    return `${localTime} ${shortTz}`.trim();
  }

  getDesc(key) {
    if (key === 'driverNotArrived' && this.answerData?.data?.eta) {
      let etaLocalTime = this.getDisplayLocalTime(this.answerData.data.eta);
      return `ETA: ${etaLocalTime}`;
    }
    if (key === 'driverOnSite' && this.answerData?.data?.ata) {
      let ataLocalTime = this.getDisplayLocalTime(this.answerData.data.ata);
      return `Actual arrival time: ${ataLocalTime}`;
    }
    if (key === 'driverHasLeft' && this.answerData?.data?.ata) {
      let ataLocalTime = this.getDisplayLocalTime(this.answerData.data.ata);
      return `Actual arrival time: ${ataLocalTime}`;
    }
    if ((key === 'driverNotFinishedLoading' || key === 'driverNotFinishedUnloading') && this.answerData?.data?.delayReason) {
      let msg = `Reason: ${this.answerData.data.delayReason}`;
      if (this.answerData.data.estimateDepartureTime) {
        let estimateDepartureTimeLocal = this.getDisplayLocalTime(this.answerData.data.estimateDepartureTime);
        msg += `\n\nEstimate Departure time: ${estimateDepartureTimeLocal}`;
      }
      return msg;
    }
    if (key === 'driverDeparted' && this.answerData?.data?.departureTime) {
      let departureTimeLocal = this.getDisplayLocalTime(this.answerData.data.departureTime);
      return `Departure time: ${departureTimeLocal}`;
    }
    if (key === 'contactFailed' && this.answerData?.data?.note) {
      return this.answerData.data.note;
    }
    return this.formDriverStatus[key]?.desc || '';
  }

  getTemplate(status): TemplateRef<any> {
    switch (status) {
      case 'driverNotArrived': return this.tplMarkDriverNotArrived;
      case 'driverOnSite': return this.tplMarkDriverArrived;
      case 'driverHasLeft': return this.tplMarkDriverHasLeft;
      case 'driverNotFinishedLoading': return this.tplMarkDriverNotFinishedLoading;
      case 'driverNotFinishedUnloading': return this.tplMarkDriverNotFinishedLoading;   // Chung template với loading
      case 'driverDeparted': return this.tplMarkDriverDeparted;
      case 'contactFailed': return this.tplMarkContactFailed;
    }
  }

  getCurrentStopTimeZone() {
    let currentStop = this.taskService.getCurrentStopEntity();
    return currentStop?.getTimeZoneStandard() || '';
  }

  getLogActions(status: DriverStatus): LogAction[] {
    switch (status) {
      case 'driverNotArrived': return ['updateETA'];
      case 'driverOnSite': return ['updateATA'];
      case 'driverHasLeft': return ['updateATA'];

      case 'driverNotFinishedLoading': 
      case 'driverNotFinishedUnloading':
        return ['noteReason', 'updateEstimateDepartureTime'];
      case 'driverDeparted': return ['updateDepartureTime'];
      case 'contactFailed': 
        if (this.contactInfo?.entity === 'Carrier') 
          return ['markContactCarrierFailed'];
        return ['markContactDriverFailed'];
      default: return [];
    }
  }

  onDriverResponseChange(data: DriverAnswerData) {
    this.updateLogHistory(data);
    this.driverResponseChange.emit(data);
  }

  disableTemplate(key: DriverStatus) {
    this.visibleTemplate[key] = false;
  }

  updateDriverStatus(value) {
    if (!this.driverStatusArr.includes(value)) {
      UIHelper.showErr(`Invalid driver status ${value}`);
      return;
    }
    try {
      this.disableTemplate(value)
      this.resetAnswerData();
      this.driverStatus = value;
      this.answerData.type = this.driverStatus;
      // Cập nhật lại thông tin từ driver
      if (value === 'driverNotArrived') this.onMarkDriverNotArrived();
      else if (value === 'driverOnSite') this.onMarkDriverArrived();
      else if (value === 'driverHasLeft') this.onMarkDriverHasLeft();
      else if (value === 'driverNotFinishedLoading' || value === 'driverNotFinishedUnloading') this.onUpdateNoteDelayReason();
      else if (value === 'driverDeparted') this.onMarkDriverDeparted();
      else if (value === 'contactFailed') this.onUpdateNoteContactFailed();
      this.onDriverResponseChange(this.answerData);
      UIHelper.showSuccess('Status update successfully');
    }
    catch (e) {
      this.driverStatus = null;
      UIHelper.showErr(e);
    }
  }

  // Khi answer thay đổi thì reset lại, không để trả ra sai thông tin
  resetAnswerData() {
    this.answerData = {};
  }

  onMarkDriverNotArrived() {
    this.answerData.data = {
      eta: DateUtil.convertLocalTime(this.tmpETA, this.getCurrentStopTimeZone())
    }
    this.tmpETA = null;
  }

  onMarkDriverArrived() {
    this.answerData.data = {
      ata: DateUtil.convertLocalTime(this.tmpATA, this.getCurrentStopTimeZone())
    }
    this.tmpATA = null;
  }

  onMarkDriverHasLeft() {
    this.answerData.data = {
      ata: DateUtil.convertLocalTime(this.tmpATA, this.getCurrentStopTimeZone())
    }
    this.tmpATA = null;
  }
  
  onMarkDriverDeparted() {
    this.answerData.data = {
      departureTime: DateUtil.convertLocalTime(this.tmpDepartureTime, this.getCurrentStopTimeZone())
    }
    this.tmpDepartureTime = null;
  }

  onUpdateNoteDelayReason() {
    this.answerData.data = {
      delayReason: this.tmpNoteDelayReason,
      estimateDepartureTime: DateUtil.convertLocalTime(this.tmpEstimateDepartureTime, this.getCurrentStopTimeZone())
    }
    this.tmpNoteDelayReason = '';
    this.tmpEstimateDepartureTime = null;
  }

  onUpdateNoteContactFailed() {
    this.answerData.data = {
      note: this.tmpNoteContactFailed
    }
    this.tmpNoteContactFailed = '';
  }
  
  shouldEnableRadio(key) {
    return this.getValue(key) === this.driverStatus;
  }

  canUpdateDriverStatus(key: DriverStatus) {
    switch (key) {
      case 'driverNotArrived': return this.tmpETA ? true : false;
      case 'driverOnSite': return this.tmpATA ? true : false;
      case 'driverHasLeft': return this.tmpATA ? true : false;
      // loading và unloading giống nhau
      case 'driverNotFinishedLoading':
      case 'driverNotFinishedUnloading':  
        return this.tmpNoteDelayReason && this.tmpEstimateDepartureTime ? true : false;
      case 'driverDeparted': return this.tmpDepartureTime ? true : false;
      case 'contactFailed': return this.tmpNoteContactFailed ? true : false;
      default: return false;
    }
  }
}
